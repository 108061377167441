// Rubrik-, Inhalts- und Vertiefungsseiten
#content-portlets {
  display: inline-block;
  background-color: #f3f2f2;
  margin-left: -25px;
  margin-right: -25px;
}

#content-portlets .portletHeader,
#content-portlets .portletHeader a {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 18px;
    color: #555555;
    font-style: italic;
}

#content-portlets .portlet {
  padding: 0;
}

.dyn .dyn-content {
  background-color: #9acee6;
  font-size: 14px;
}
.dyn .dyn-content li a {
  background-color: #9acee6;
  font-size: 14px;
}
