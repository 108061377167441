.top-mega-menu {
    li.mega-dropdown,
    ul.dropdown-section,
    ul.dropdown-section > li {
        padding: 0.2em 0.3em;
    }
    .mega-dropdown-menu {
        box-shadow: 0 5px 10px #888888;
        .dropdown-header {
            font-size: inherit;
        }
    }
    /*
    @media (max-width: @plone-screen-sm-min) {
        .mega-dropdown-menu {
            overflow: hidden;

            .mega-dropdown-menu-lv1,
            .mega-dropdown-menu-lv2 {
                position: absolute;
                height: 1px;
                left: 100%;
                transition: left 0.5s;
            }

        }

        .open {
            .mega-dropdown-menu {
                .mega-dropdown-menu-lv1 {
                    left: 0;
                    height: 100%;
                }
            }
        }

    }
*/
}
@media (max-width: @plone-screen-sm-min) {
    .top-mega-menu {
        .container {
            padding: 0 !important;
        }
        .row {
            margin: 0 !important;
        }
        .collapse.in {
            padding: 0;
            li a {
                font-size: 1.5em;
                padding-top: 1em !important;
                padding-bottom: 1em;
                border-bottom: 2px solid #fff;
                padding-left: 1em;
                display: block;
		line-height:1em;
            }
        }
        .expand li.dropdown {
            width: 1px;
            position: relative !important;
        }
        .expand li.dropdown.open {
            width: auto;
            position: static !important;
        }
        .expand li.open ul.dropdown-menu {
            left: 100%;
            position: absolute;
            top: 0;
            width: 100%;
            > li a {
                padding-left: 0;
                line-height: 0.2em;
                font-size: 18px;
            }
        }
        .expand li.back {
            background-color: #555555;
            a {
                color: #fff !important;
                padding-left: 3em;
                border: none !important;
            }
        }
        .expand li.open li.hidden {
            display: block !important;
        }
        li.back h5 a::before {
            content: "<";
            font-family: FontAwesome;
            font-size: 24px;
            left: 20px;
            line-height: 1.5;
            margin-bottom: 0;
            position: absolute;
            top: 7px;
            z-index: 1;
        }
        ul.mega-dropdown-menu-lv2 > li > a::before {
            content: '';
        }
        .expand li.open ul.mega-dropdown-menu-lv1 li {
            padding-left: 0 !important;
        }
        .expand li.open ul.mega-dropdown-menu-lv2 {
            left: 100%;
            position: absolute;
            top: 0;
            width: 100%;
            display: none;
        }
        .expand .col-sm-3 {
            position: static;
        }
    }
}
.col-menu {
    width: 20%;
    float: left;
}

.mobilemenu {
    @media (min-width: @plone-screen-sm-up) {
        display: none !important;
    }
}

.desktopmenu {
    @media (max-width: @plone-screen-sm-min) {
        display: none !important;
    }
}
#mega-dropdown-menu-lv1-a:after,
#mega-dropdown-menu-lv1-a {
    color: @bgv-blue-link;
}
#mega-dropdown-menu-lv1-a:hover,
#mega-dropdown-menu-lv1-a:hover:after,
#mega-dropdown-menu-lv1-a:focus,
#mega-dropdown-menu-lv1-a:focus:after {
    color: @bgv-red-base;
} 
