span.category-link {
        a {color:black;font-size:@plone-font-size-21;font-weight:normal;font-style:normal;line-height:30px;}
        h2.portlet_title::after {
    font-family: 'FontAwesome';
    content: "\f105";
    font-size:@plone-font-size-21;
    padding-left:6px;
        }
}

span.category-link.red {
        a:hover,
        a::after {
    color:@bgv-red-base;
    text-decoration:none;
	}
}
span.category-link.blue {
        a:hover,
        a::after {
    color:@bgv-blue-link;
    text-decoration:none;
	}
}
span.externallink a,
span.linkinternal-black a,
span.linkinternal a {
        font-weight: bold;
        font-size:@plone-font-size-h5;
        color:#000;
        text-decoration:none;
        &:hover {color:@bgv-red-base;}
        }
a.slide-read-more {
    font-size: @plone-font-size-large;
    font-family: 'Fira Sans';
    font-weight: bold;
}
a.slide-read-more::after {
    font-family: 'Glyphicons Halflings';
    content: "\e080";
    color:white;
    font-size:10px;
}

span.linkinternal-white::after {
    font-family: 'Glyphicons Halflings';
    content: "\e080";
    color:#FFFFFF;
    font-size:10px;
}

span.linkinternal::after {
    font-family: 'Glyphicons Halflings';
    content: "\e080";
    color:@bgv-red-base;
    font-size:10px;
}
span.externallink-red::after {
    font-family: 'FontAwesome';
    content: "\f08e";
    font-style:normal;
    padding-left:4px;
}
span.externallink-tuerkis::after {
    font-family: 'FontAwesome';
    content: "\f08e";
    font-style:normal;
    padding-left:4px;
}
span.externallink::after {
    font-family: 'FontAwesome';
    content: "\f08e";
    font-style:normal;
    padding-left:8px;
}
span.externallink-red a,
span.externallink-red::after,
span.linkinternal-black:hover:after,
span.externallink:hover:after {
	color:@bgv-red-base;
}
span.externallink-tuerkis a {
	color:#007c84;
}
span.externallink-red:hover:after {
	color:#000
	}
span.download-link:hover:before,
span.download-link a:hover,
span.externallink-red a {
	&:hover {color:#000;text-decoration:none}
	}
.event-descr span.linkinternal-black:hover:after {color:@bgv-blue-base;}           
span.linkinternal-black::after {
    font-family: 'FontAwesome';
    content: "\f105";
    color:@plone-gray-dark;
    font-size:16px;
    padding-left:4px;
}
a.relatedLink::before {
    font-family: 'Glyphicons Halflings';
    font-size:17px;
    content: "\e080";
    color:@bgv-red-base;
    padding-right:10px;
}
span.mehr-link-teaser-blue,
span.anker {
    a {color:@bgv-blue-base;
        &:hover {color:@bgv-blue-light;text-decoration:underline}
        }
}
span.mehr-link-teaser-red a {
	color:@bgv-red-base;
	&:hover {color:#000;text-decoration:underline;}
}
span.mehr-link-teaser-tuerkis a {
	color:#007c84;
	&:hover {color:#000;text-decoration:underline;}
}
span.mehr-link-teaser-red:hover:before {
	color:#000;text-decoration:none;
}
span.mehr-link-teaser-red::before {
    font-family: 'FontAwesome';
    color:@bgv-red-base;
    font-size:14px;
    content: "\f105";
    padding-right:8px;
}
span.mehr-link-teaser-blue::before {
    font-family: 'FontAwesome';
    color:@bgv-blue-base;
    font-size:14px;
    content: "\f105";
    padding-right:8px;
}
span.mehr-link-teaser-tuerkis::before {
    font-family: 'FontAwesome';
    color:#007c84;
    font-size:14px;
    content: "\f105";
    padding-right:8px;
}
span.anker::after {
    font-family: FontAwesome;
    content: '\f103';
    padding-left: 10px;
    color:@bgv-blue-light;
}
span.anker::before {
font-family: FontAwesome;
content: '\f13d';
padding-right: 10px;
font-size: 17px;
margin-bottom: 0em;
position: relative;
top: -2px;
color:@bgv-blue-light;
}
span.download-link a,
span.contenttype-file a {
        color:@bgv-red-base;
        &:hover {text-decoration:none;}
}
span.download-link a:hover{
    text-decoration:underline;
}
span.download-link-tuerkis a {
        color:#007c84;
        &:hover {text-decoration:underline;}
}
span.download-link a::before,
span.contenttype-file a::before {
font-family: FontAwesome;
content: '\f019';
padding-right: 10px;
font-size: 17px;
margin-bottom: 0em;
position: relative;
color:@bgv-red-base;
}
span.download-link-tuerkis a::before {
  font-family: FontAwesome;
  content: '\f019';
  padding-right: 10px;
  font-size: 17px;
  margin-bottom: 0em;
  position: relative;
  color:#007c84;
}
span.portlet-link-black 
	a {
	color:black;
	font-weight:500;
	&:after {
	padding-left: 6px;
	font-family: FontAwesome;
	content: '\f105';
	color:@bgv-blue-base;
 	}
}
span.portlet-link-blue 
	a {
	color:@bgv-blue-base;
	font-weight:normal;
	font-style:italic;
	&:after {
	font-family: FontAwesome;
	padding-left: 6px;
	font-style:normal;
	content: '\f105';
	color:@bgv-blue-base;
 	}
}
a.backlink {
	background:#555;
	color:white !important;
	&:before {
		font-family: FontAwesome;
		content: '\f104';
		padding-right: 10px;
		font-size: 24px;
		margin-left:-15px;
	}
	&:hover {
	background:#555 !important;
	color:white;
	}
}
