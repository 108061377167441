//*// BOOTSTRAP GRID //*//

//*// This is exactly BOOTSTRAP GRID, replace it for your system



// Container widths
.container {
  .container-fixed();

/*
  @media (min-width: @plone-screen-sm-min) {
    width: @plone-container-sm-full;
  }
  @media (min-width: @plone-screen-md-min) {
    width: @plone-container-md-full;
  }
*/
  @media (min-width: @plone-screen-lg-min) {
    width: @plone-container-lg-full;
  }

}

body.userrole-authenticated .container {

  .container-fixed();

/*
  @media (min-width: @plone-screen-sm-min) {
    width: @plone-container-sm;
  }
  @media (min-width: @plone-screen-md-min) {
    width: @plone-container-md;
  }
*/
  @media (min-width: @plone-screen-lg-min) {
    width: @plone-container-lg;
  }

}


// Fluid container
.container-fluid {
  .container-fixed();
}


// Row
.row {
  .clearfix();
  .make-row();
}


// Columns
.make-grid-columns();


// Extra small grid
.make-grid(xs);


// Small grid
@media (min-width: @plone-screen-sm-min) {
  .make-grid(sm);
}


// Medium grid
@media (min-width: @plone-screen-md-min) {
  .make-grid(md);
}


// Large grid
@media (min-width: @plone-screen-lg-min) {
  .make-grid(lg);
}


// toolbar needs to match grid
#edit-zone.plone-toolbar-top .plone-toolbar-container {
  .container-fixed();
  @media (min-width: @plone-screen-sm-min) {
    width: @plone-container-sm;
  }
  @media (min-width: @plone-screen-md-min) {
    width: @plone-container-md;
  }
  @media (min-width: @plone-screen-lg-min) {
    width: @plone-container-lg;
  }
  margin: 0 auto; height: 50px;
}
#edit-zone.plone-toolbar-top.compressed .plone-toolbar-container {width: auto;}
