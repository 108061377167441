//*// PORTLETS //*//
.portlet {
    //border: @plone-portlet-border;
    //margin-bottom: 20px;
    //border-radius: @plone-border-radius-base;
    //box-shadow:  0 1px 3px rgba(0,0,0,.05);
    //> :first-child {border-radius: 4px 4px 0 0;}
    //> :last-child {border-radius: 0 0 4px 4px;}
    .portletHeader {
        //background: @plone-portlet-header-bg;
        //font-weight: 300;
        //	font-size: @plone-font-size-large;
        //		padding: 13px 15px;
        color: @bgv-text-gray;
    }
    &.portletCalendar .portletHeader {
        text-align: center;
        position: relative;
        .calendarPrevious,
        .calendarNext {
            width: 30px;
            height: 30px;
            padding: 0 1px 2px;
            border-radius: 50%;
            position: absolute;
            display: inline-block;
            left: 5px;
            font-size: @plone-font-size-h3;
            margin-top: -3px;
            line-height: 28px;
            font-weight: @plone-font-weight-light;
        }
        .calendarPrevious:hover,
        .calendarNext:hover {
            background: @plone-portlet-content-bg;
            text-decoration: none;
        }
        .calendarNext {
            right: 5px;
            left: inherit;
        }
    }
    .portletContent {
        // text-indent: -26px;
        //margin-left: 24px;
        font-size: @plone-font-size-h5;
        //border-top: @plone-portlet-border;
        //background: @plone-portlet-content-bg;
        //font-weight: @plone-font-weight-normal;
        & > *,
        & > div:not(.portalMessage) {
            //all but ul and tables and portalMessage
            //padding: 10px 15px;
            //margin: 0;
            font-weight: @plone-font-weight-normal;
            //font-weight: @plone-font-weight-regular;
            color: #000;
        }
        > ul {
            padding: 0;
            list-style: none;
            position: relative;
            > li {
                a {
                    display: block;
                    padding: 10px 15px 26px 30px;
                    position: relative;
                    color: #000;
                    z-index: 1;
                    background: @bgv-gray;
                }
                //> a {border-top: @plone-portlet-border;}
                //&:first-child > a {border-top: 0;}
                & a:before {
                    content: "•";
                    position: absolute;
                    font-size: @plone-font-size-h3+1;
                    margin-top: -9px;
                    left: 15px;
                    color: lighten(@plone-portlet-list-bullet, 15%);
                }
            }
            ul {
                padding-left: 15px;
                list-style: none;
            }
            .portletItemDetails {
                display: block;
                z-index: 0;
                padding: 0 15px 10px 30px;
                font-weight: @plone-font-weight-light;
                position: relative;
                margin-top: -26px;
                font-size: @plone-font-size-small;
                color: @plone-portlet-list-small-text;
            }
            li:hover {
                background: @plone-portlet-list-hover-bg;
                a:before {
                    color: @plone-portlet-list-bullet;
                }
            }
        }
        table {
            width: 100%;
            text-align: center;
            text-align: middle;
            border-collapse: collapse;
            border-spacing: 0;
            tr {
                line-height: 30px;
            }
            thead {
                background: @plone-portlet-footer-bg;
                border-bottom: @plone-portlet-border;
                th {
                    color: @plone-gray-light;
                    font-weight: @plone-font-weight-light;
                    height: 40px;
                    text-align: center;
                }
            }
            td {}
            td > * {
                border-radius: 50%;
                display: inline-block;
                width: 20px;
                height: 20px;
                line-height: 20px;
            }
            .cal_has_events {
                position: relative;
                a:hover {
                    background: @plone-portlet-event-bullet;
                    text-decoration: none;
                    box-shadow: 0 0 0 5px @plone-portlet-event-bullet;
                }
                a:after {
                    content: "•";
                    position: absolute;
                    margin-left: 50%;
                    left: -3px;
                    font-size: @plone-font-size-large+2;
                    color: lighten(@plone-portlet-list-bullet, 15%);
                    margin-top: 9px;
                }
                a:hover:after {
                    color: @plone-portlet-list-bullet;
                }
            }
            .cal_next_month,
            .cal_prev_month {
                color: #cccccc;
                &.cal_has_events > a {
                    color: lighten(@plone-portlet-list-bullet, 15%);
                }
                &.cal_has_events > a:after {
                    color: lighten(@plone-portlet-list-bullet, 25%);
                }
                &.cal_has_events > a:hover {
                    color: darken(@plone-link-color,15%);
                }
                &.cal_has_events > a:hover:after {
                    color: @plone-portlet-list-bullet;
                }
            }
            .today > * {
                background: @plone-portlet-contrast-bg;
                font-weight: 500;
                box-shadow: 0 0 0 5px @plone-portlet-contrast-bg;
            }
            //using shadow to expand background is because then table is only 20px per cell and not 30px
            tbody tr:first-child td > * {
                margin-top: 10px;
            }
            tbody tr:last-child td > * {
                margin-bottom: 10px;
            }
        }
    }
    .formControls {
        border: 0;
        background: none;
        padding: 0;
        box-shadow: none;
    }
    .portletFooter {
        border-top: @plone-portlet-border;
        background: @plone-portlet-footer-bg;
        padding: 10px 15px;
        font-size: @plone-font-size-small;
        font-weight: @plone-font-weight-regular;
        box-shadow: inset 0 10px 10px -10px rgba(0,0,0,.05);
    }
}
// Custom Portlet
.portletDownloadPortlet {
    padding: 15px;
    margin-bottom: 5px;
    background: @bgv-gray;
    ul {
        list-style: none;
        padding-left: 0;
        > li {
            text-indent: -25px;
            margin-left: 25px;
            margin-bottom: 10px;
            > span > a {
                font-size: @plone-font-size-h5;
            }
        }
    }
}
.portletStaticText,
.portletPLZSuche,
.portletKontaktPortlet {
    font-size: @plone-font-size-h5;
    padding: 15px;
    margin-bottom: 5px;
    background: @bgv-gray;
    .portletHeader,
    h3 {
        margin-bottom: 10px;
        margin-top: 0;
        color: @bgv-red-base;
        font-size: @plone-font-size-24;
        font-style: normal;
        line-height: 1.1;
    }
}
.portletTextplusPortlet {
    margin-bottom: 0;
    .portletHeader {
        display: none;
    }
    .portletItem {
        background: @bgv-gray;
        border-top: 5px solid #004b93;
        padding-top: 3px;
    }
    p:first-of-type {
        padding: 0 !important;
    }
    p:last-of-type {
        padding-bottom: 20px !important;
    }
    p,
    h2,
    h3,
    h4,
    h5 {
        padding: 0 15px;
    }
    p {
        margin-bottom: 5px;
    }
}
#front-page-portlets-bottom .portletHeader {
    padding-bottom: 0 !important;
}
#front-page-portlets-bottom .portletHeader,
#front-page-portlets-bottom .portletHeader a {
    line-height: 1.22222;
    margin-bottom: 0;
    font-size: @plone-font-size-large;
    color: @bgv-text-gray;
    font-style: italic;
}
#front-page-portlets-bottom {
    h3 {
        margin-bottom: 10px;
    }
}
#front-page-portlets-bottom .portletContent {
    padding-top: 10px;
    blockquote {
        margin-left: 0;
        margin-top: 20px;
        p {
            font-style: italic;
            font-size: 28px;
            line-height: 33px;
        }
    }
}
#front-page-portlets-bottom .portletContent img {
    margin-bottom: 20px;
}
#front-page-portlets-bottom .portletContent > p {
    line-height: 22px;
    font-size: 16px;
    margin-bottom: 10px;
    span.zitatschrift {
        font-size: 14px;
        line-height: 16px !important;
    }
}
.portletKontaktPortlet {
    p.contact-position,
    p.contact-phone {
        margin-bottom: 0;
    }
    p > span {
        padding-left: 8px;
    }
    p.contact-phone > span {
        padding-left: 11px;
    }
}
.cards-portlets .portletKontaktPortlet {
    background: transparent;
    padding: 0;
    h3 {
        font-size: @plone-font-size-28;
        margin-bottom: 15px;
        line-height: 1.6em;
        margin-top: 0;
        border-bottom: 3px dotted @plone-gray-dark;
    }
}
.portlet-sidebar > div {
    margin-bottom: 30px;
}
.PLZBox {
    padding: 15px 0;
}
.PLZBox.form-group {
    display: inline-block;
	width: ~"calc(100% - 35px)";
}
.portletPLZSuche .btn-default {
    margin-left: -35px;
}
.cards-portlets .portletPLZSuche {
	background:transparent;
	.portletHeader {
        margin-bottom: 15px;
        line-height: 1.6em;
        margin-top: 0;
        border-bottom: 3px dotted @plone-gray-dark;
	}
	p {font-size:inherit;}
}
#searchPLZ [type="submit"] {
    display: none;
}
.front-page-section-top {
    margin-bottom: -40px;
    padding-top: 40px;
    .portletPLZSuche,
    .portletStaticText {
        padding: 0;
    }
    h2,
    .portletHeader {
        line-height: 1.1;
        font-size: 2.2vw;
        font-weight: 400;
        word-wrap: normal;
        color: @bgv-red-base;
    }
    .portletHeader {
        padding-top: 15px;
        margin-top: 4px;
    }
    p {
        font-size: @plone-font-size-h5;
        color: #000;
        margin: 0 0 10px;
    }
    .portletContent {
        background: transparent;
    }
    span.linkinternal a {
        font-weight: bold;
    }
    > div:nth-child(1) {
        padding-left: 0;
    }
}
@media only screen and (min-width: 85.5625em) {
    .front-page-section-top h2, .front-page-section-top .portletHeader {font-size:36px;}
}
#front-page-portlets-bottom .portletStaticText,
#media-aside .portletStaticText {
    padding: 0;
    background: transparent !important;
}
//*// Navigation
.portletNavigationTree nav.portletContent > ul > li a:hover {
    text-decoration: none !important;
    color: @bgv-blue-link !important;
}
.portletNavigationTree nav.portletContent > ul > li a {
    padding: 10px 40px 10px 15px;
    border-top: 3px solid #fff;
}
.portletNavigationTree nav.portletContent > ul > li strong {
    padding: 10px 15px;
    border-top: @plone-portlet-border;
    background: @plone-portlet-footer-bg;
    color: @plone-gray;
    display: block;
    font-weight: @plone-font-weight-regular;
}
.portletNavigationTree {
    margin-bottom: 40px;
    .portletContent {
        border-top: 0;
        a:before {
            content: "" !important;
        }
        > ul > li > div > a {
            font-size: @plone-font-size-20;
		}
        > ul > li > a {
            font-size: @plone-font-size-20;
            display: block;
            background: darken(@plone-portlet-header-bg,2%);
            //&:before {content: ""; }
            &:hover {
                background: @plone-portlet-footer-bg;
            }
            //&:hover:after {content:"⟩"; position: absolute; right: 10px; font-size: @plone-font-size-h3; margin-top:-3px; color: #000;}
        }
        // > ul > li > ul a {padding-left:40px;}
        > ul ul {
            padding-left: 0;
        }
        .navTreeCurrentNode > a {
            background: @bgv-gray-lighter;
            color: @bgv-blue-link;
            //&:after {content:"⟩"; position: absolute; right: 10px; font-size: @plone-font-size-large; margin-top:-3px; color: @plone-gray-light;}
        }
        a.navTreeFolderish.navTreeItemInPath,
        a.navTreeFolderish.navTreeCurrentItem {
            background-image: url("/++theme++bgv/dist/img/bg_leftnav_level1_active.png")!important;
            background-repeat: no-repeat;
            background-position: right;
            font-style: italic;
        }
        ul.navTreeLevel0 > li > a {
            // , .navTreeLevel1 a.navTreeFolderish {
            background-image: url("/++theme++bgv/dist/img/bg_leftnav_level1.png");
            background-repeat: no-repeat;
            background-position: right;
        }
        .navTreeLevel0 a.navTreeFolderish:hover,
        .navTreeLevel1 a.navTreeFolderish:hover {
            background-color: @bgv-gray-lighter;
            color: @bgv-blue-link;
            background-image: url("/++theme++bgv/dist/img/bg_leftnav_level1_hover.png");
            background-repeat: no-repeat;
            background-position: right;
            z-index: 333;
            content: "" !important;
        }
        .navTreeLevel2 > li > a {
            padding-left: 30px;
        }
        //	.navTreeLevel2 .visualNoMarker a:before {content:"";font-family:FontAwesome;font-size:12px;left:18px;position:relative;top:36px;z-index:200;line-height:0;}
        .navTreeLevel1 {
            li a {
                span {
                    font-size: @plone-font-size-large;
                    position: relative;
                    display: block;
                    padding-left: 20px;
                    &:before {
                        position: absolute;
                        top: 0;
                        left: 2px;
                        z-index: 1;
                        font-family: FontAwesome;
                        content: "\f105";
                    }
                }
                &.navTreeFolderish {
                    color: @bgv-blue-link;
                    background-image: none !important;
                    &.navTreeCurrentNode,
                    &.navTreeItemInPath {
                        border-bottom: 3px solid #FFF;
                        span:before {
                            transform: rotate(90deg);
                            font-style: normal;
                        }
                    }
                }
                border: none;
            }
            .navTreeLevel2 {
                border-bottom: 3px solid #FFF;
            }
        }
        .navTreeLevel3 > li > a {
            padding-left: 50px;
        }
    }
}
#upgrade-products .configlets,
#install-products .configlets,
#activated-products .configlets,
#broken-products .configlets {
    li {
        padding: @plone-padding-base-horizontal;
        border-top: 1px dotted @plone-table-border-color;
    }
    li:first-child {
        border-top: 0;
    }
    h3,
    p {
        margin: 0;
    }
    .portletContent:last-child {
        margin-bottom: 0;
    }
    .pull-right {
        margin: 0 0 @plone-padding-base-horizontal @plone-padding-base-horizontal;
    }
}
//*// Search
// See forms: input + submit (manual)
//*// Manage portlets
.managedPortletActions {
    float: right;
    margin-top: -@plone-padding-base-vertical;
    form {
        display: inline-block;
    }
}
.managedPortlet {
    .portlet;
    &.inheritedPortlet {
        margin: 5px 15px;
        .portletHeader {
            padding: 3px 15px;
        }
    }
    .portletHeader {
        font-weight: 300;
    }
    &.blockedPortlet {
        opacity: 0.7;
    }
}

div[id^="column-bgv-frontpage-bottom-portlet-"] {
  margin-bottom: 40px;
}
