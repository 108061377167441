/* Show/Hide default portlet managers links */
body {   // seems I can't use the :not(.userrole-manager,.userrole-site-administrator)
    #edit-zone {
        #portlet-manager-plone\.footerportlets,
        #portlet-manager-plone\.leftcolumn,
        #portlet-manager-plone\.rightcolumn {
            display: none
        }
    }
}
body.userrole-manager,
body.userrole-site-administrator {
    #edit-zone {
        #portlet-manager-plone\.footerportlets,
        #portlet-manager-plone\.leftcolumn,
        #portlet-manager-plone\.rightcolumn {
            display: block
        }
    }
}

/* Show/Hide frontpage portlet managers links */
body:not(.portaltype-frontpage) {
    #edit-zone {
        #portlet-manager-bgv\.frontpage\.bottom,
        #portlet-manager-bgv\.frontpage\.top,
        #portlet-manager-bgv\.frontpage\.sidebar {
            display: none;
        }
    }
}

/* Show/Hide cardsview portlet managers links */
body {   // seems I can't use the :not(.userrole-manager,.userrole-site-administrator)
    #edit-zone {
        #portlet-manager-nva\.bsfolderviews\.card\.bottomleft,
        #portlet-manager-nva\.bsfolderviews\.card\.bottomright {
            display: none
        }
    }
}
body.template-cardsview,
body.template-purecardsview {
    #edit-zone {
        #portlet-manager-nva\.bsfolderviews\.card\.bottomleft,
        #portlet-manager-nva\.bsfolderviews\.card\.bottomright {
            display: block;
        }
    }
}
