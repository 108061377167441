//*// SITE NAV //*//
.plone-nav {
    margin-bottom: 0;
    padding-left: 0; // Override default ul/ol
    list-style: none;
    &:extend(.clearfix all);
    > li {
        text-align: center;
        position: relative;
        //    display: block;
        > a {
            position: relative;
            display: block;
            //    padding: 10px 22px 10px 15px;
            font-weight: 400;
            font-size: 1.8vw;
            color: black;
            &:hover,
            &:focus {
                text-decoration: none;
                color: @bgv-red-base;
            }
        }
    }
}
/*
@media (max-width: @plone-container-sm) {
    .plone-nav > li > a {
        font-size: 12px;
    }
}
@media (min-width: @plone-container-md) {
    .plone-nav > li > a {
        font-size: @plone-font-size-21;
    }
}
*/

@media (min-width: @plone-container-lg) {
    .plone-nav > li > a {
        font-size: @plone-font-size-24;
    }
}

.top-mega-menu {
    background-color: @plone-sitenav-bg;
}
.plone-navbar {
    .clearfix();
    position: relative;
    //min-height: @plone-sitenav-height; // Ensure a navbar always shows (e.g., without a .navbar-brand in collapsed mode)
    //margin-bottom: @plone-sitenav-margin-bottom;
    border: 1px solid transparent;
    // box-shadow: 0 1px 3px rgba(0,0,0,.17);
    // Prevent floats from breaking the navbar
    &:extend(.clearfix all);
}
.plone-navbar-header {
    .clearfix(); //without extend
    @media (min-width: @plone-grid-float-breakpoint) {
        float: left;
    }
}
.plone-navbar-collapse {
    overflow-x: visible;
    padding-right: @plone-sitenav-padding-horizontal;
    padding-left: @plone-sitenav-padding-horizontal;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
    &:extend(.clearfix all);
    -webkit-overflow-scrolling: touch;
    display: none;
    &.in {
        //overflow-y: auto;
        display: block;
    }
    @media (min-width: @plone-screen-sm-up) {
        width: auto;
        border-top: 0;
        box-shadow: none;
        &.plone-collapse {
            display: block !important;
            height: auto !important;
            padding-bottom: 0; // Override default setting
            overflow: visible !important;
        }
        &.in {
            overflow-y: visible;
        }
        // Undo the collapse side padding for navbars with containers to ensure
        // alignment of right-aligned contents.
        .navbar-fixed-top &,
        .navbar-static-top &,
        .navbar-fixed-bottom & {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
.plone-navbar-toggle {
    z-index: 1; //just in case
    position: relative;
    float: right;
    margin-top: 6px;
    margin-bottom: 3px;
    padding: 6px 10px;
    background-color: @bgv-blue-base;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid white;
    border-radius: @plone-border-radius-base;
    // We remove the `outline` here, but later compensate by attaching `:hover`
    // styles to `:focus`.
    &:focus {
        outline: 0;
    }
    &:hover {
        background-color: darken(@plone-sitenav-link-hover-bg, 15%);
        border-color: darken(@plone-sitenav-link-hover-bg, 25%);
    }
    // Burgerbar (uncomment for a ≡ menu and comment menu just below)
    /*
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background: white;
    margin-top: 2px;
  }
  .icon-bar + .icon-bar {
    margin-top: 5px;
  }
  .icon-bar + .icon-bar + .icon-bar {
    margin-bottom: 3px;
  }
  //end burgerbar icon-bar
  */
    // Menu (That option with a word, witch is language-dependant, is to ensure a higher tap ratio on the menu)
    .icon-bar:after {
        position: relative;
        color: white;
    }
    .icon-bar + .icon-bar:after {
        content: "";
    }
    //end menu icon-bar
    @media (min-width: @plone-screen-sm-up) {
        display: none;
    }
}
.sitenav-navbar-toggle {
    z-index: 1; //just in case
    position: absolute;
    top: 0;
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;
    a {
        vertical-align: super;
    }
    a,
    .fa-ellipsis-h {
        color: @bgv-blue-base;
    }
    .fa-ellipsis-h {
        imargin-left: 10px;
        font-size: 26px;
        vertical-align: bottom;
    }
    @media (min-width: @plone-screen-sm-up) {
        display: none;
    }
}
.plone-navbar-nav {
    margin: -1px -@plone-sitenav-padding-horizontal;
    > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: @plone-line-height-computed;
    }
    > .selected > a {
        &,
        &:hover,
        &:focus {
            color: @bgv-red-base;
            background-color: @plone-sitenav-link-hover-bg;
        }
    }
    // Uncollapse the nav
    @media (min-width: @plone-grid-float-breakpoint) {
        float: left;
        > li {
            float: left;
            > a {
                padding-top: @plone-sitenav-padding-vertical;
                padding-bottom: @plone-sitenav-padding-vertical;
            }
        }
        &.navbar-right:last-child {
            margin-right: -@plone-sitenav-padding-horizontal;
        }
    }
}
