@media (max-width: @plone-screen-sm-up) {
    #content-header {
        .navbar {
            min-height: 30px;
            .navbar-nav {
                display: flex;
                justify-content: space-between;
            }
        }
    }
    #portal-logo {
        top: 0;
    }
    li#document-action-leichtesprache > a:before,
    li#document-action-gaeberdensprache > a:before {
        background: none;
        left: 0;
    }
    .navbar-collapse-sitemap {
        padding: 20px 0;
        .col-xs-8 {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .navbar-search-header button:active,
    .navbar-search-header button:hover {
        color: @bgv-blue-light;
        background-color: transparent;
        border-color: @bgv-blue-light;
    }
    .navbar-form {
        margin: 20px 0;
    }
    .front-page-section-top {
        margin-bottom: 0;
    }
    .media-block .col-md-8 {
        padding-left: 0;
        padding-right: 0;
        padding-top: 10px;
    }
    .event-box .col-md-12 {
        margin-left: 0;
        margin-right: 0;
    }
    .event-descr {
        margin-bottom: 10px;
    }
    .documentActions ul {
        float: none;
        display: inline;
        > li {
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
    #portal-footer-wrapper {
        text-align: left;
    }
    .footer-action .col-xs-12 {
        padding-left: 15px;
    }
    .navbar-footer,
    .footer-newsletter {
        list-style-type: none;
        padding-left: 0;
    }
    h2.row-headline-bottom {
        height: 20px;
    }
    .header-category,
    .header-content {
        margin-top: 0;
    }
    .front-page-section-top > div:nth-child(1) {
        padding-left: 15px;
    }
    #portal-column-one .portletNavigationTree {
        display: none;
    }
    li#karriere,
    li#die-bg-verkehr,
    li#presse,
    li#redaktion,
    li#test,
    li#document-action-review_list {
        display: none;
    }
    div.bgdirektLinkMobile img {
        max-height: 16px;
    }
}
@media only screen and (max-width: 40em) {
    h2.row-headline {
        background: @bgv-text-gray;
        margin-top: 0;
        margin-bottom: 0;
    }
    h2.row-headline.grey > span > span {
        background-color: transparent;
        color: white;
        left: 20px;
        top: -14px;
        font-size: 4.38596vw;
    }
    .top-container {
        margin-top: 0;
    }
    .col-md-3,
    .col-md-4,
    .col-md-12 {
        padding-left: 15px;
        padding-right: 15px;
    }
    .bgdirektLink a {
        float: right;
        position: relative;
        top: -127px;
        right: -38px;
    }
}
.redcircle h3 {
    color: #fff;
}
@media only screen and (max-width: @plone-screen-md-min) {
    .redcircle {
        color: #000000;
        height: 100%;
        width: 100%;
        margin-bottom: 10px;
        h3 {
            color: #d40f14;
            font-size: @plone-font-size-28;
            padding-top: 15px;
            padding-bottom: 0;
           }
        p {
           line-height: 24px !important;
           font-size: 16px;
           margin: 0 0 10px;
           padding-left: 0;
           width: 100%;
          }
        span.linkinternal-white::after {
           font-family: 'Glyphicons Halflings';
           content: "\e080";
           color:#d40f14;
           font-size:10px;
           }
        span.linkinternal-white a {
           font-size:@plone-font-size-h5;
           text-decoration:none;
           font-weight: bold;
           &:hover {color:@bgv-red-base;}
           }
    }
}
