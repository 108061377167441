@import "variables.plone.less";
.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.nav > li {
    position: relative;
    display: block;
}
.nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
}
.nav > li > a.selected,
.nav > li > a:hover,
.nav > li > a:focus {
    text-decoration: none;
    background-color: #eee;
}
.nav > li.disabled > a {
    color: #777;
}
.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
    color: #777;
    text-decoration: none;
    cursor: not-allowed;
    background-color: transparent;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    background-color: #eee;
    border-color: #337ab7;
}
.nav .nav-divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}
.nav > li > a > img {
    max-width: none;
}
.navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 1px solid transparent;
    margin-right: -17px
}
.navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
}
#navbar-search-collapsible .input-group {
    margin-top: 30px;
    margin-bottom: 10px;
}
/*
.navbar-collapse.in {
    overflow-y: auto;
}
*/
@media (min-width: 768px) {
    .navbar-collapse {
        width: auto;
        border-top: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important;
    }
    .navbar-collapse.in {
        overflow-y: visible;
    }
    .navbar-fixed-top .navbar-collapse,
    .navbar-static-top .navbar-collapse,
    .navbar-fixed-bottom .navbar-collapse {
        padding-right: 0;
        padding-left: 0;
    }
}
.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
    max-height: 340px;
}
@media (max-device-width: 480px) and (orientation: landscape) {
    .navbar-fixed-top .navbar-collapse,
    .navbar-fixed-bottom .navbar-collapse {
        max-height: 200px;
    }
}
.container > .navbar-header,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
}
@media (min-width: 768px) {
    .container > .navbar-header,
    .container-fluid > .navbar-header,
    .container > .navbar-collapse,
    .container-fluid > .navbar-collapse {
        margin-right: 0;
        margin-left: 0;
    }
}
.navbar-static-top {
    z-index: 1000;
    border-width: 0 0 1px;
}
@media (min-width: 768px) {
    .navbar-static-top {
        border-radius: 0;
    }
}
.navbar-fixed-top,
.navbar-fixed-bottom {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
}
@media (min-width: 768px) {
    .navbar-fixed-top,
    .navbar-fixed-bottom {
        border-radius: 0;
    }
}
.navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
}
.navbar-fixed-bottom {
    bottom: 0;
    margin-bottom: 0;
    border-width: 1px 0 0;
}
.navbar-brand {
    float: left;
    height: 50px;
    padding: 15px;
    font-size: 18px;
    line-height: 20px;
}
.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}
.navbar-brand > img {
    display: block;
}
@media (min-width: 768px) {
    .navbar > .container .navbar-brand,
    .navbar > .container-fluid .navbar-brand {
        margin-left: -15px;
    }
}
.navbar-search-toggle {
    position: absolute;
    float: left;
    left: 140px;
    top: -12px;
    padding: 2px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 0;
    span.glyphicon-search {
        font-size: 18px;
        color: @bgv-blue-base;
    }
    @media (min-width: @plone-screen-sm-up) {
        display: none !important;
    }
}
.navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.navbar-toggle:focus {
    outline: 0;
}
.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}
.navbar-toggle .icon-bar + .icon-bar {
    margin-top: 4px;
}
@media (min-width: 768px) {
    .navbar-toggle {
        display: none;
    }
}
@media (max-width: @plone-screen-sm-min) {
    .navbar {
        margin-bottom: 0;
    }
}
.navbar-nav {
    margin: 7.5px -15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin: 0;
    #die-bg-verkehr > a {
        color: @bgv-blue-base;
        font-weight: bold;
    }
}
@media (max-width: @plone-screen-sm-min) {
    .navbar-nav {
        display: block;
        li {
            text-align: left;
        }
    }
}
.navbar-nav > li > a {
    //    padding-top: 10px;
    //    padding-bottom: 10px;
    line-height: 20px;
}
@media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .navbar-nav .open .dropdown-menu > li > a,
    .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 5px 15px 5px 25px;
    }
    .navbar-nav .open .dropdown-menu > li > a {
        line-height: 20px;
    }
    .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-nav .open .dropdown-menu > li > a:focus {
        background-image: none;
    }
}
@media (min-width: @plone-screen-sm-up) {
    .navbar-nav {
        margin: 0;
    }
    .navbar-nav > li {
        //padding:15px 0;
        float: left;
    }
    .navbar-nav > li > a {
        padding-top: 15px;
        padding-bottom: 15px;
        line-height: inherit;
    }
}
.navbar-form {
    padding: 10px 15px;
    margin: 8px -15px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
}
@media (min-width: 768px) {
    .navbar-form .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }
    .navbar-form .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    .navbar-form .form-control-static {
        display: inline-block;
    }
    .navbar-form .input-group {
        display: inline-table;
        vertical-align: middle;
    }
    .navbar-form .input-group .input-group-addon,
    .navbar-form .input-group .input-group-btn,
    .navbar-form .input-group .form-control {
        width: auto;
    }
    .navbar-form .input-group > .form-control {
        width: 100%;
    }
    .navbar-form .control-label {
        margin-bottom: 0;
        vertical-align: middle;
    }
    .navbar-form .radio,
    .navbar-form .checkbox {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle;
    }
    .navbar-form .radio label,
    .navbar-form .checkbox label {
        padding-left: 0;
    }
    .navbar-form .radio input[type="radio"],
    .navbar-form .checkbox input[type="checkbox"] {
        position: relative;
        margin-left: 0;
    }
    .navbar-form .has-feedback .form-control-feedback {
        top: 0;
    }
}
@media (max-width: 767px) {
    .navbar-form .form-group {
        margin-bottom: 5px;
    }
    .navbar-form .form-group:last-child {
        margin-bottom: 0;
    }
}
@media (min-width: 768px) {
    .navbar-form {
        width: auto;
        padding-top: 0;
        padding-bottom: 0;
        margin-right: 0;
        margin-left: 0;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}
.navbar-nav > li > .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
    margin-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.navbar-btn {
    margin-top: 8px;
    margin-bottom: 8px;
}
.navbar-btn.btn-sm {
    margin-top: 10px;
    margin-bottom: 10px;
}
.navbar-btn.btn-xs {
    margin-top: 14px;
    margin-bottom: 14px;
}
.navbar-text {
    margin-top: 15px;
    margin-bottom: 15px;
}
@media (min-width: 768px) {
    .navbar-text {
        float: left;
        margin-right: 15px;
        margin-left: 15px;
    }
}
@media (min-width: @plone-screen-sm-up) {
    .navbar-left {
        float: left !important;
    }
    .navbar-right {
        float: right !important;
        margin-right: -15px;
    }
    .navbar-right ~ .navbar-right {
        margin-right: 0;
    }
}
.navbar-default .navbar-nav > li > a {
    color: #000;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: @bgv-red-base;
    background-color: transparent;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: #555;
}
.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus {
    color: #ccc;
    background-color: transparent;
}
.navbar-default .navbar-toggle {
    border-color: #ddd;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #ddd;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #888;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border-color: #e7e7e7;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    color: #555;
    background-color: #e7e7e7;
}
@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #777;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #333;
        background-color: transparent;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
        color: #555;
        background-color: #e7e7e7;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
        color: #ccc;
        background-color: transparent;
    }
}
.navbar-default .navbar-link {
    color: #777;
}
.navbar-default .navbar-link:hover {
    color: #333;
}
.navbar-default .btn-link {
    color: #777;
}
.navbar-default .btn-link:hover,
.navbar-default .btn-link:focus {
    color: #333;
}
.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:hover,
.navbar-default .btn-link[disabled]:focus,
fieldset[disabled] .navbar-default .btn-link:focus {
    color: #ccc;
}
.navbar-inverse {
    background-color: #222;
    border-color: #080808;
}
.navbar-inverse .navbar-brand {
    color: #9d9d9d;
}
.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
    color: #fff;
    background-color: transparent;
}
.navbar-inverse .navbar-text {
    color: #9d9d9d;
}
.navbar-inverse .navbar-nav > li > a {
    color: #9d9d9d;
}
.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus {
    color: #fff;
    background-color: transparent;
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
    color: #fff;
    background-color: #080808;
}
.navbar-inverse .navbar-nav > .disabled > a,
.navbar-inverse .navbar-nav > .disabled > a:hover,
.navbar-inverse .navbar-nav > .disabled > a:focus {
    color: #444;
    background-color: transparent;
}
.navbar-inverse .navbar-toggle {
    border-color: #333;
}
.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
    background-color: #333;
}
.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #fff;
}
.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
    border-color: #101010;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
    color: #fff;
    background-color: #080808;
}
@media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
        border-color: #080808;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
        background-color: #080808;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
        color: #9d9d9d;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #fff;
        background-color: transparent;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
        color: #fff;
        background-color: #080808;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
        color: #444;
        background-color: transparent;
    }
}
.navbar-inverse .navbar-link {
    color: #9d9d9d;
}
.navbar-inverse .navbar-link:hover {
    color: #fff;
}
.navbar-inverse .btn-link {
    color: #9d9d9d;
}
.navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link:focus {
    color: #fff;
}
.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link[disabled]:focus,
fieldset[disabled] .navbar-inverse .btn-link:focus {
    color: #444;
}
nav.plone-navbar {
    min-height: auto;
}
.plone-navbar-toggle {
    margin-top: 20px;
    margin-bottom: 20px;
}
.navbar-nav > li:hover > a {
    background: url("/++theme++bgv/dist/img/bg_mainnav_level1.png") no-repeat center bottom;
}
@media only screen and (max-width: 50em) {
    .navbar-nav > li:hover > a {
        background: none;
    }
}
.top-mega-menu li.mega-dropdown,
.top-mega-menu ul.dropdown-section,
.top-mega-menu ul.dropdown-section > li {
    padding-bottom: 0 !important;
}
.plone-nav > li > a.selected {
    color: #d40f14;
    text-decoration: none;
}
.plone-nav > li > a.selected::before {
    color: #d40f14;
    font-family: FontAwesome;
    5yy padding-left: 0;
    padding-right: 6px;
    font-style: normal;
    content: '\f105';
}
#portal-globalnav-wrapper {
    .navbar-header {
        text-align: center;
    }
    .plone-navbar-toggle {
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;
        float: none;
        font-size: 1.5em;
        color: #014c93;
    }
    .fa {
        margin-left: 8px;
        font-size: 1.6em;
        position: relative;
        top: 6px;
    }
}
#content-header ul.dropdown-menu {
    > li {
        a {
            color: black;
        }
        a::after {
            content: '';
        }
        a::before {
            font-family: FontAwesome;
            5yy padding-left: 0;
            padding-right: 6px;
            font-style: normal;
            content: '\f105';
            color: black;
        }
    }
}
.navbar-collapse-sitemap ul > li a:after {
    padding: 0 10px 0 5px;
    color: #000;
    content: "\007C";
    font-weight: lighter;
}
.navbar-collapse-sitemap #document-action-kontakt a:after {
    content: "";
}


#content-header .navbar-collapse-sitemap ul > li ul {
    padding: 20px 16px;
    margin-top: 14px !important;
    border: 1px solid #f0f1f1;
    &::before {
        background-attachment: scroll;
        background-clip: border-box;
        background-color: rgba(0, 0, 0, 0);
        background-image: url("++theme++bgv/dist/img/bg_topnav_layer.png");
        background-origin: padding-box;
        background-position: 15px bottom;
        background-repeat: no-repeat;
        background-size: contain;
        content: " ";
        display: block;
        height: 24px;
        left: 0;
        position: absolute;
        top: -24px;
        width: 100%;
        z-index: 1;
    }
    >li {
        &:hover {
            color: @bgv-red-base;
        }
        a {
            text-decoration: none;
            font-size: @plone-font-size-large;
            white-space: nowrap;
            &:hover {
                color: @bgv-red-base;
                &::after {
                    color: @bgv-red-base;
                }
            }
        }
        a::after {
            content: '';
        }
    }
}
@media (max-width: @plone-screen-sm-min) {
    .navbar-collapse-sitemap ul > li a:after {
        content: none;
    }
}
.navbar-collapse-sitemap .nav > li > a {
    padding-left: 0;
    padding-right: 0;
    color: #000;
    font-size:@plone-font-size-15;
    &:hover,
    &:focus {
        color: @bgv-red-base;
        background-color: transparent;
    }
}
