//*// FOOTER //*//

#portal-footer-wrapper {
  padding: 30px 15px;
  background-color: @plone-footer-bg;
  color: @plone-gray-lighter;
  text-align: center;
  clear: both;
  p {
    border-bottom: 1px solid @plone-gray-lighter;
    padding-bottom: 28px;
  }
  a {
    color: white;
    &:hover {color: white;}
  }
  .portletWrapper {
    display: inline-block;
    &:first-child {
      display: block;
      border-bottom: 1px solid @plone-gray-dark;
      padding-bottom: 1em;
      margin-bottom: .5em;
    }
  }
  .portlet {
    background: transparent;
    border: 0;
    font-weight: @plone-font-weight-light;
    box-shadow: none;
    margin-bottom: 0;
    .portletContent {
      padding: 0;
      background: transparent;
      border: 0;
      > * {padding: inherit;}
      li {
        background: transparent;
        display: inline-block;
        border: 0;
        white-space: nowrap;
        padding: 10px 30px;
      }
    }
    &#portal-colophon .portletContent a { //remove if remove Powered by Plone
        padding: 10px 30px;
    }
    .portletItem:before, .portletItem a:before {
      content: "";
    }
    .portletItem a {
      padding: 0; border:0;
    }
    .portletActions {
      text-transform: uppercase;
      font-size: @plone-font-size-small;
      font-weight: @plone-font-weight-bold;
      margin-right: 15px;
    }
  }
}
// Footer custom

@media (min-width: 768px) {
.footer-newsletter {
	xline-height:92px;
        list-style-type: none;
        li {display:inline-block;}
	li > span i.fa-envelope {color:@bgv-blue-light;font-size:@plone-font-size-h4}
	li > span a {font-style: normal;font-weight: 400;line-height:1; margin-bottom:0; color:white;font-size:@plone-font-size-20}
	}
.footer-action nav {
        text-align:left;
        ul {list-style-type: none; padding-left:0;
        > li {
        display: inline-block;
        xline-height:92px;
        > a, > span {font-style: normal;font-weight: 400;line-height:1; margin-bottom:0; color:white;font-size:@plone-font-size-20}
    a, a:focus, a:link {
        color:white;
        &:hover {color:white}
        }
                        span::after, a > span::after {
                        content: '|';
                        padding: 0 5px;
                        }
        }
}
}
}
.footer-action nav ul > li a > span:hover::after {text-decoration:none}
#portal-siteactions {
  display: inline-block;
  text-transform: uppercase;
  font-size: @plone-font-size-small;
}
#portal-siteactions li {
  font-weight: bold;
  display: inline-block;
  margin-left: 30px;
  &:first-child {
    margin-left: 0;
  }
}
