div.outer-wrapper {
  background-color: #fff;
}

a#personaltools-login,
.pat-plone-modal.login-display-none,
.login-display-none,
.searchSection,
.portaltype-frontpage .plone-breadcrumb {
    display: none;
}
.image-caption,
img.captioned {
    max-width: 100%;
    font-size: 17px;
    margin-bottom: 0;
    font-style: italic;
    color: @bgv-blue-base;
    padding-top: 5px;
}
h1.documentFirstHeading {
    color: @bgv-blue-base;
    font-size: @plone-font-size-firstheading;
}
.folderDescription p,
.documentDescription {
    color: @plone-gray-dark;
    font-size: @plone-font-size-24;
    line-height: 1.25;
    font-weight: 400;
}
.documentDescription {
    font-style: italic !important;
}
#content blockquote p,
blockquote {
    font-size: 30px;
    font-style: italic;
    line-height: 1.17241;
    margin: 0 0 0 15%;
    font-weight: 300;
    display: inline-block;
    color: @bgv-blue-base !important;
}
blockquote .fa {
    font-size: 60px;
    line-height: 1;
    margin-bottom: 0;
}
.font-italic {
    font-style: italic;
}
#content ol > li {
    font-size: @plone-font-size-21;
    font-style: italic;
    padding-bottom: 8px;
}
#parent-fieldname-text,
#content p,
#content ol,
#content ul,
a.relatedLink,
.quote-text,
span.anker,
span.contenttype-file,
.bottom-container p {
    font-size: @plone-font-size-21;
    font-weight: 400;
    font-style: normal;
    line-height: 30px;
    //margin-bottom: 20px;
}
.portaltype-frontpage .front-page-section-top p,
.portaltype-frontpage .front-page-section-top ol,
.portaltype-frontpage .front-page-section-top ul,
.portaltype-frontpage .top-container p,
.portaltype-frontpage .bottom-container p
{
    line-height: 24px;
}

#content p.callout {
    font-style: italic;
    font-size: @plone-font-size-28;
    background: @bgv-gray;
    font-weight: normal;
}
.quote-claim,
.quote-text,
.quote-link {
    background: @bgv-gray;
    display: block;
    padding: 15px;
}
.quote-link-tuerkis {
    background: #ebf6f6;
    display: block;
    padding: 15px;
}
.quote-claim-tuerkis,
.quote-text-tuerkis {
    background: #ebf6f6;
    display: block;
    padding: 15px;
}
h3,
h3.quote.claim,
#content ul > li,
#relatedItemBox header {
    font-style: italic;
    padding-bottom: 8px;
}
#parent-fieldname-text ul {
    list-style: none;
    > li:before {
        position: relative;
        top: -2px;
        left: -15px;
        z-index: 1;
        font-family: FontAwesome;
        content: '\f111';
        color: @bgv-blue-link;
        font-size: 10px;
        font-style: normal;
    	margin-left:-10px;
        line-height: 0;
	}
}
h3.quote-claim {
    margin-bottom: 0;
    margin-top: 20px;
}
h3.quote-claim-tuerkis {
    margin-bottom: 0;
    margin-top: 20px;
}
.quote-link {
    a {
        padding-left: 10px;
        color: #014C93;
    }
}
.quote-link-tuerkis {
    a {
        padding-left: 10px;
        color: #007c84;
    }
}
.quote-link:before,
.quote-link-tuerkis:before {
    z-index: 1;
    font-family: 'Glyphicons Halflings';
    content: "\e080";
    line-height: inherit;
    font-size: @plone-font-size-large;
    color: @bgv-blue-base;
}
.quote-link-tuerkis:before {
    color: #007c84;
}

p.font-blue {
    color: @bgv-blue-base !important;
}
.zitatschrift {
    font-size: @plone-font-size-h5;
}
//16px
.portletDownloadPortlet #relatedItemBox header {
    font-size: @plone-font-size-24;
}
#relatedItemBox {
    ul {
        padding-left: 0 !important;
        list-style: none;
    }
    header {
        font-size: @plone-font-size-28;
        margin-bottom: 15px;
        padding-bottom: 5px;
        border-bottom: 3px dotted @plone-gray-dark;
    }
}
a.relatedLink {
    color: @bgv-red-base;
    &:hover {
        color: @plone-gray-dark;
        text-decoration: none;
    }
}
.image-left {
    margin-top: 0.2em;
}
// Style

.quote-link:last-child {
  margin-bottom: 15px;
}

.quote-text {
  padding-bottom: 20px;
  li {
    margin-left: 40px;
  }
}

#portaltab-index_html {
    display: none;
}
nav.plone-navbar {
    min-height: auto;
}
.plone-navbar-toggle {
    margin-top: 10px;
    margin-bottom: 10px;
}
.navbar-nav > li:hover > a {
    background: url("/++theme++bgv/dist/img/bg_mainnav_level1.png") no-repeat center bottom;
}
@media only screen and (max-width: 50em) {
    .navbar-nav > li:hover > a {
        background: none;
    }
}
.open > .dropdown-menu {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
    display: block;
}
.dropdown-menu {
    opacity: 0.3;
    -webkit-transform-origin: top;
    transform-origin: top;
    /*  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  -webkit-transform: scale(1, 0);
  transition: all 0.6s linear;
  -webkit-transition: all 0.6s linear; */
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 100%;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #fff;
}
.caret-up {
    width: 0;
    height: 0;
    border-left: 4px solid rgba(0, 0, 0, 0);
    border-right: 4px solid rgba(0, 0, 0, 0);
    border-bottom: 4px solid;
    display: inline-block;
    margin-left: 2px;
    vertical-align: middle;
}
// header CTA lik e search and bgdirekt
.header-cta {
    list-style: none;
    li {}
}
.bgdirektLink {
    padding-top: 6px;
    padding-right: 7px;
    }
.bgdirekt {
    float: right;
    z-index: 1;
}
.bgdirekt img {
    width: 198px;
    max-width: 198px;
    height: auto;
}
.plzSearch {
    border: none;
    padding: 7px 16px;
}
.LSBox [type="submit"] {
    background: @bgv-blue-base;
    color: white;
    border: none;
    padding: 7px 16px;
}
.LSBox [type="submit"]:hover {
    background: @bgv-red-base;
}
.header-cta input[type="text"] {
    background: @bgv-gray;
    border: 1px solid @bgv-gray;
}
li#document-action-leichtesprache,
li#document-action-gaeberdensprache {
    margin-left: 24px;
}
li#document-action-leichtesprache > a:before,
li#document-action-gaeberdensprache > a:before {
    content: ' ';
    top: 0;
    position: absolute;
    left: -20px;
    z-index: 1;
    display: block;
    width: 1.2em;
    height: 1.2em;
}
li#document-action-leichtesprache > a:after {
    padding: 0 5px;
    color: #000;
    content: "\007C";
}
@media (max-width: @plone-screen-sm-min) {
    li#document-action-leichtesprache > a:after {
        content: none;
    }
}
li#document-action-gaeberdensprache > a:after {
    content: ' ';
    padding: 0;
}
li#document-action-gaeberdensprache > a:before {
    background: url("/++theme++bgv/dist/img/bg_icon_gs.png") no-repeat top left;
    background-size: contain;
}
li#document-action-leichtesprache > a:before {
    background: url("/++theme++bgv/dist/img/bg_icon_ls.png") no-repeat top left;
    background-size: contain;
}
li#document-action-print > a {
    background: url("/++theme++bgv/dist/img/bg_icon_print.png") no-repeat right center;
    background-size: contain;
    padding-right: 35px;
}
li#document-action-webcode > a {
    background: url("/++theme++bgv/dist/img/bg_icon_recommend.png") no-repeat right center;
    background-size: contain;
    padding-right: 35px;
}
li#document-action-totop > a {
    background: url("/++theme++bgv/dist/img/bg_icon_top.png") no-repeat right center;
    background-size: contain;
    padding-right: 35px;
}
.documentActions {
    border-top: 1px solid #000;
    padding-top: 20px;
    ul {
        float: right;
    }
}
.documentActions > ul::after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
}
.documentActions > ul > li {
    display: inline-block;
    float: left;
    margin-right: 20px;
}
.documentActions > ul > li > a {
    display: inline-block;
    color: #000;
    font-size: 18px;
    line-height: 1.7;
    margin-bottom: 0;
    max-height: 32px;
}
.documentActions > ul > li > a:hover {
    color: @bgv-red-base;
    text-decoration: none;
}
// Mega menu
.top-mega-menu li.mega-dropdown,
.top-mega-menu ul.dropdown-section > li {
    padding-bottom: 0 !important;
}
.top-mega-menu ul.dropdown-section {
    margin-bottom: 30px;
}
.mega-menu {
    padding: 10px 0 ! important;
    width: 540px;
    border-radius: 0;
    margin-top: 0;
}
.mega-menu li {
    display: inline-block;
    float: left;
    padding: 3px 0;
}
.mega-menu li.mega-menu-column {
    margin-right: 20px;
    width: 150px;
}
.mega-menu .nav-header {
    padding: 0 !important;
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #ddd;
}
.mega-menu img {
    padding-bottom: 10px;
}
// Titel bilder
/*
.header-content {
	height: 13.88889vw;
	max-height: 190px;
	background-color:@bgv-blue-base;
}
.header-content > .container {
	width: 100%;
	max-width: 1368px;
	margin: 0 auto;
	height: inherit;
	max-height: 190px;
}
.header-content .teaser-container {
	position: relative;
	// top: 40%;
	transform: translateY(40%);
        margin-top: 4%;
	color: white;
	width: 48%;
}

@media only screen and (max-width: 50em) {
	.header-content .teaser-container {
		}
	}
@media only screen and (min-width: 85.5625em) { .header-content .teaser-container { padding-left: 0px; } }
.header-content .teaser-container h1 { font-size: 36px; line-height: 1.11111; margin-bottom: 0em; color: white; padding-left: 1.75439vw; }
@media only screen and (max-width: 40em) { .header-content .teaser-container h1 { font-size: 6.57895vw; } }
@media only screen and (min-width: 40.0625em) and (max-width: 50em) { .header-content .teaser-container h1 { font-size: 4.5vw; } }
@media only screen and (min-width: 50.0625em) and (max-width: 64em) { .header-content .teaser-container h1 { font-size: 2.63158vw; } }
@media only screen and (min-width: 64.0625em) and (max-width: 85.5em) { .header-content .teaser-container h1 { font-size: 2.63158vw; } }
@media only screen and (min-width: 85.5625em) { .header-content .teaser-container h1 { font-size: 36px; } }
@media only screen and (max-width: 50em) { .header-content .teaser-container h1 { padding-left: 3vw; } }
@media only screen and (min-width: 85.5625em) { .header-content .teaser-container h1 { padding-left: 24px; } }

@media only screen and (max-width: 50em) { .header-content { height: auto; max-height: 10000px; }
  .header-content > .inside { height: auto; max-height: 10000px; }
  .header-content .teaser-container { width: 100%; height: auto; position: static; transform: none; top: 0; background-color: #004b93; padding-top: 3.65497vw; padding-bottom: 3.65497vw; padding-right: 1.75439vw; } }
@media only screen and (max-width: 50em) and (max-width: 50em) { .header-content .teaser-container { padding-top: 6.25vw; } }
@media only screen and (max-width: 50em) and (min-width: 85.5625em) { .header-content .teaser-container { padding-top: 50px; } }
@media only screen and (max-width: 50em) and (max-width: 50em) { .header-content .teaser-container { padding-bottom: 6.25vw; } }
@media only screen and (max-width: 50em) and (min-width: 85.5625em) { .header-content .teaser-container { padding-bottom: 50px; } }
@media only screen and (max-width: 50em) and (max-width: 50em) { .header-content .teaser-container { padding-right: 3vw; } }
@media only screen and (max-width: 50em) and (min-width: 85.5625em) { .header-content .teaser-container { padding-right: 24px; } }
*/
/*
.header-category {
	height: 25.65789vw;
	max-height: 351px;
	background-color:@bgv-blue-base;
//	background: url("/++theme++bgv/dist/img/header_category.jpg") no-repeat top center;
	background-size: cover;
	border-top:1px solid transparent;
}
.header-category > .container {
	width: 100%;
	max-width: 1368px;
	margin: 0 auto;
	height: inherit;
	max-height: 351px;
}
.header-category .teaser-container { position: relative; top: 40%; transform: translateY(40%); color: white; padding-left: 1.75439vw; width: 50%; }
@media only screen and (max-width: 50em) { .header-category .teaser-container { padding-left: 3vw; } }
@media only screen and (min-width: 85.5625em) { .header-category .teaser-container { padding-left: 24px; } }
.header-category .teaser-container h1 { font-size: 2.63158vw; line-height: 1.11111; margin-bottom: 0em; }
@media only screen and (min-width: 85.5625em) { .header-category .teaser-container h1 { font-size: 36px; } }
.header-category .teaser-container p { font-size: 1.60819vw; line-height: 1.36364; margin-bottom: 0em; }
@media only screen and (min-width: 85.5625em) { .header-category .teaser-container p { font-size: 22px; } }

@media only screen and (max-width: 50em) { .header-category { height: auto; max-height: 10000px; }
  .header-category > .inside { height: auto; max-height: 10000px; }
  .header-category .teaser-container { width: 100%; height: auto; position: static; transform: none; top: 0; background-color: #004b93; padding-top: 3.65497vw; padding-bottom: 3.65497vw; padding-right: 1.75439vw; }
  .header-category .teaser-container h1 { font-size: 36px; line-height: 1.11111; margin-bottom: 0.41667em; }
  .header-category .teaser-container p { font-size: 21px; line-height: 1.33333; margin-bottom: 0em; } }
@media only screen and (max-width: 50em) and (max-width: 50em) { .header-category .teaser-container { padding-top: 6.25vw; } }
@media only screen and (max-width: 50em) and (min-width: 85.5625em) { .header-category .teaser-container { padding-top: 50px; } }
@media only screen and (max-width: 50em) and (max-width: 50em) { .header-category .teaser-container { padding-bottom: 6.25vw; } }
@media only screen and (max-width: 50em) and (min-width: 85.5625em) { .header-category .teaser-container { padding-bottom: 50px; } }
@media only screen and (max-width: 50em) and (max-width: 50em) { .header-category .teaser-container { padding-right: 3vw; } }
@media only screen and (max-width: 50em) and (min-width: 85.5625em) { .header-category .teaser-container { padding-right: 24px; } }
@media only screen and (max-width: 50em) and (max-width: 40em) { .header-category .teaser-container h1 { font-size: 6.57895vw; } }
@media only screen and (max-width: 50em) and (min-width: 40.0625em) and (max-width: 50em) { .header-category .teaser-container h1 { font-size: 4.5vw; } }
@media only screen and (max-width: 50em) and (min-width: 50.0625em) and (max-width: 64em) { .header-category .teaser-container h1 { font-size: 2.63158vw; } }
@media only screen and (max-width: 50em) and (min-width: 64.0625em) and (max-width: 85.5em) { .header-category .teaser-container h1 { font-size: 2.63158vw; } }
@media only screen and (max-width: 50em) and (min-width: 85.5625em) { .header-category .teaser-container h1 { font-size: 36px; } }
@media only screen and (max-width: 50em) and (max-width: 40em) { .header-category .teaser-container p { font-size: 3.83772vw; } }
@media only screen and (max-width: 50em) and (min-width: 40.0625em) and (max-width: 50em) { .header-category .teaser-container p { font-size: 2.625vw; } }
@media only screen and (max-width: 50em) and (min-width: 50.0625em) and (max-width: 64em) { .header-category .teaser-container p { font-size: 1.53509vw; } }
@media only screen and (max-width: 50em) and (min-width: 64.0625em) and (max-width: 85.5em) { .header-category .teaser-container p { font-size: 1.53509vw; } }
@media only screen and (max-width: 50em) and (min-width: 85.5625em) { .header-category .teaser-container p { font-size: 21px; } }
*/
// Front-page
@media only screen and (min-width: 1130px) {
.redcircle {
background: url("/++theme++bgv/dist/img/redcircle.svg") no-repeat 0 0;
background-size: contain;
height: 280px;
width: 280px;
float: left;
display: block;
text-align: center;
padding-top: 20%;
color: white;
h3 {
font-size: 1.6vw;
font-weight: 400;
}
p,
a {
color: white;
font-style: normal;
}
p {
line-height: 1.1vw !important;
padding-left: 10%;
width: 90%;
}
a {
font-weight: bold;
&:hover {
    text-decoration: underline;
}

}
}}
@media only screen and (min-width: 85.5625em) {
.redcircle {height:280px;width:280px;
h3 {font-size: 1.2vw;}
p {font-size: 0.8vw;}
}
}

@media only screen and (max-width: 640px) {
h1.documentFirstHeading {
margin-top:50px;
margin-left:-4px;
}
}
@media (min-width: @plone-screen-md-min) and (max-width: 1130px) {
.redcircle {
background: url("/++theme++bgv/dist/img/redcircle.svg") no-repeat 0 0;
background-size: contain;
float: left;
display: block;
text-align: center;
height: 17.4vw;
width: 17.4vw;
padding-top: 25px;
margin-bottom: 5px;
h3 {
    font-size: 1.3vw;
   }
p {
   color: #FFFFFF;
   width: 180px;
   font-size: 12px;
   line-height: 1.5vw !important;
  }
a {
color: white;
font-style: normal;
font-weight: bold;
&:hover {
    text-decoration: underline;
}

  }

}
}
@media (max-width: @plone-screen-md-min) {
.redcircle {
float: none;
display: block;
height: 20vw;
width: 20vw;
//height: auto;
text-align: left;
padding-top: 0;
color: black;
h3 {
    font-size: 1.2;
}
p,
a {
    color: black;
    font-style: normal;
    font-size: 18px;
}
h3 {
    font-weight: normal;
    color: @bgv-red-base;
}
}
}
@media (max-width: @plone-screen-sm-up) {
#column-bgv-frontpage-top-portlet-2,
#column-bgv-frontpage-top-portlet-3,
#column-bgv-frontpage-top-portlet-4 {
padding-left: 15px;
}
}
@media (min-width: @plone-screen-sm-up) and (max-width: @plone-screen-md-min) {
.redcircle h3 {
font-size: 2.2vw !important;
}
#column-bgv-frontpage-top-portlet-2,
#column-bgv-frontpage-top-portlet-3,
#column-bgv-frontpage-top-portlet-4 {
padding-left: 0;
}
}
@media (max-width: @plone-screen-sm-min) {
h3,
.front-page-section-top h2,
.front-page-section-top .portletHeader {
font-size: 28px;
}
}
// media section
.top-container {
margin-top: 3.5em;
}
h2.cards-label {
position: relative;
top: -60px;
font-size: 18px;
line-height: 1;
color: #555555;
font-style: italic;
> span {
position: absolute;
top: -10px;
margin-left: 40px;
}
}
div.cards-halfcircle {
height: 60px;
width: 120px;
border-radius: 120px 120px 0 0;
-moz-border-radius: 120px 120px 0 0;
-webkit-border-radius: 120px 120px 0 0;
background: white;
margin-left: 20px;
-webkit-transform: rotate(-180deg);
transform: rotate(-180deg);
}
h2.row-headline > span > span {
position: relative;
display: inline-block;
top: 15px;
left: 58px;
z-index: 0;
background-color: @bgv-gray;
border-top-right-radius: 50%;
border-top-left-radius: 50%;
padding: 36px 12px 0;
font-size: 18px;
line-height: 1;
margin-bottom: -5em;
color: #555555;
font-style: italic;
}
h2.row-headline-bottom {
height: 10px;
> span > span {
position: relative;
display: inline-block;
top: -75px;
left: 58px;
z-index: 0;
background-color: @bgv-gray;
border-top-right-radius: 50%;
border-top-left-radius: 50%;
padding: 36px 12px 0;
font-size: 18px;
line-height: 1;
//margin-bottom: -5em;
color: #555555;
font-style: italic;
}
}
.wide-above,
.wide-below {
background: @bgv-gray;
}
h2.row-headline.grey > span > span {
background-color: white;
}

.media-main h3 {
font-style: normal;
font-size: @plone-font-size-28;
margin-top: 0;
@media (max-width: @plone-screen-md-min) {
margin-top: 0.5rem;
}
}
.media-block {
margin-bottom: 40px;
//float: left;
&:last-child {
margin-bottom: 0;
}
&:after {
content: "";
display: table;
clear: both;
}
img.responsive {
max-width: 100%;
display: block;
height: auto;
}
p {
line-height: 24px !important;
}
}
.portlet-sidebar p {
font-size: @plone-font-size-base !important;
font-style: italic !important;
line-height: 20px !important;
margin-bottom: 10px;
> span a {
font-style: normal !important;
font-size: @plone-font-size-h5 !important;
}
}
.media-descr {
font-size: 19px;
}
.media-date {
color: @bgv-text-gray;
font-style: italic;
font-size: 19px;
}
.event-descr span.linkinternal-black a {
font-weight: 400 !important;
font-size: 22px;
line-height: 1.13636;
&:hover,
&:hover:after {
color: @bgv-blue-base;
}
}
.event-descr span.linkinternal-black::after {
font-size: 20px !important;
}
#media-aside {
border-left: 1px dotted #000000;
padding-top: 18px;
@media (max-width: @plone-screen-sm-max) {
border-left: none;
}
}
// images
.image-inline {
display: block;
max-width: 100%; // Part 1: Set a maximum relative to the parent
height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}
.event-box .media-icon {
text-align: center;
padding-bottom: 10px;
}
.more-events {
margin-right: 20px;
}
.more-events a {
margin-top: 15px;
}
#front-page-portlets-bottom {
margin-top: 40px;
}
// Slider Carousel
.carousel-inner {
background: transparent url(../dist/img/bg_homepage_header.png) repeat-x scroll left top / contain;
}
.carousel-caption {
text-shadow: none;
h3 {
margin-left: 0;
font-size: 2.6vw;
}
}
.carousel-caption p {
font-size: 1.2vw;
margin-left: 0;
}
@media (min-width: 1492px) {
.carousel-caption h3 {
font-size: @plone-font-size-h1;
}
.carousel-caption p {
font-size: 21px;
}
}
.carousel-inner > .item > img {
width: 100% !important;
}
.current-slide {
height: 28.5vh;
max-height: 369px;
}
.teaser-slides {
//float:left;
height: 10vw;
max-height: 132px;
> div {
position: relative;
top: 50%;
transform: translateY(-50%);
color: white;
}
a {
color: white;
font-style: italic;
}
}
/*
@media (max-width:@plone-screen-sm-max) {
.carousel-inner {background:@bgv-blue-base;background-image:none;}
.carousel-caption {
h3 {font-size:@plone-font-size-base;margin-left:0}
p {display:none;}
}
}
*/
// Image Mixins
// - Responsive image
// - Retina image
// Responsive image
//
// Keep images from scaling beyond the width of their parents.
.img-responsive {
display: block;
max-width: 100%; // Part 1: Set a maximum relative to the parent
height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}
// Retina image
//
// Short retina mixin for setting background-image and -size. Note that the
// spelling of `min--moz-device-pixel-ratio` is intentional.
.img-retina(@file-1x; @file-2x; @width-1x; @height-1x) {
background-image: url(@{file-1x});
@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and ( min--moz-device-pixel-ratio: 2),
only screen and ( -o-min-device-pixel-ratio: 2/1),
only screen and ( min-device-pixel-ratio: 2),
only screen and ( min-resolution: 192dpi),
only screen and ( min-resolution: 2dppx) {
background-image: url(@{file-2x});
background-size: @width-1x @height-1x;
}
}
// Tables
.table {
color: @bgv-text-gray;
}
.table tbody {
padding-bottom: 3px;
border-bottom: 4px solid #555555;
}
span.thead {
background: @bgv-blue-base;
color: white !important;
}
.table-striped>tbody>tr:nth-of-type(even) {
background-color: @bgv-gray !important;
}
.table-striped>tbody>tr:nth-of-type(odd) {
background-color: white !important;
}
//.table-striped>tbody>tr:nth-of-type(1),

.table tr th {
background: @bgv-blue-base !important;
color: white;
}
.table tr td {
color:black;
}
.table caption {
font-style: italic;
font-size: @plone-font-size-28;
font-weight: normal;
color: @plone-gray-dark;
}
// Tab
.tab-content {
background: @bgv-gray;
padding: 30px;
}
.nav-tabs {
margin-bottom: 0 !important;
> li {
float: left;
margin-bottom: -1px;
> a {
    margin-right: 4px !important;
    margin-bottom: 0;
    background-color: #e3e3e3;
    color: @plone-gray-dark;
    &:hover {}
}
&.active > a {
    &,
    &:hover,
    &:focus {
	background-color: @bgv-blue-base;
	cursor: default;
	color: white;
    }
}
}
}
// Accordion
.panel-title,
.panel-body,
.panel-title > a {
font-size: @plone-font-size-21;
line-height: 30px;
}
.panel-title > a {
background: #f3f2f2 url("/++theme++bgv/dist/img/bg_leftnav_level1_active.png") no-repeat right center;
display: block;
margin-bottom: 0 !important;
padding: 8px 60px 8px 8px;
color: @bgv-blue-link;
text-decoration: none;
}
.panel-title > a.collapsed {
background: #f3f2f2 url("/++theme++bgv/dist/img/bg_leftnav_level1_hover.png") no-repeat right center;
}
// Card
.folder_contents > div > div {
font-size: @plone-font-size-h5;
}
.cards-wrapper {
padding: 40px 15px;
}
@media (max-width: @plone-screen-sm-min) {
.container.cards-wrapper .row {
margin-left:0;margin-right:0;
}	
}
.cards-img {
img {
padding-top: 3px;
padding-bottom: 10px;
}
}
.card-red {
border-top: 5px solid @bgv-red-base;
}
.card-blue {
border-top: 5px solid @bgv-blue-base;
}
.bottom-container {
margin-top: 20px;
.cards-item {
min-height: 43px;
}
.bottom-cards {
margin-bottom: 40px;

.col-sm-6 {
    padding-bottom: 40px;
}
}
}
@media (max-width:750px) {
.bottom-cards .folder_contents .row {margin-left:-15px; margin-right:-15px;}
}


// Listing View
body.template-listing_view article.entry span.summary a {
font-size: 28px;
}

// Collection
body.portaltype-collection article {
#content-core table {
font-size: 21px;
}

article.entry span.summary a {
font-size: 28px;
}

article.tileItem {
border-bottom: 1px dotted #000;
padding-bottom: 10px;
h2.tileHeadline {
font-size: 28px;
}
span.description {
font-size: 21px;
}
div.tileFooter a {
font-size: 21px;
color: transparent;
&:after {
content: "> Mehr Infos";
margin-left: -62px;
color: #d40f14;

}
}
}
}

// Suche
body.template-search {
input[type="text"] {
background: #f3f2f2 none repeat scroll 0 0;
}
input {
border: 1px solid #ccc;
color: #000;
font-size: 14px;
box-shadow: 0 1px 1px rgba(0,0,0,0.075) inset;
height: 34px;
line-height: 1.42857;
padding: 6px 12px;
transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
input[type="submit"] {
background: #004b93 none repeat scroll 0 0;
padding: 7px 16px;
color: #fff;
}
input[type="submit"]:hover {
background: #d40f14 none repeat scroll 0 0;
}
#search-filter-toggle {
background: #555 none repeat scroll 0 0;
color: #fff;
font-size: 14px;
color: #555;
text-shadow: none;
&:after {
content: "Ergebnisse filtern";
color: #fff;
margin-left: -107px;
}
}
.actionMenu dt {
position: absolute;
left: 0;
top: 110px;
}
.actionMenu dd {
position: absolute;
left: 0;
top: 145px;
}
div.input-group {
margin-bottom: 34px;
}
nav#searchResultsSort {
a {
color: #000;
font-size: 18px;
cursor: pointer;
}
span {
color: #000;
font-size: 18px;
}

}
a#title-link {
font-size: 28px;
}
p.discreet {
color: black;
}
div.listingBar {
font-size: 18px;
}

ol.searchResults li {
border-bottom: 1px dotted #ccc;
cite {
font-size: 18px;
}
}
}

// Sitemap
body.template-sitemap {
div.documentDescription {
display: none;
}

ul.navTreeLevel0 li a {
font-size: 28px;
}
ul.navTreeLevel1 li a,
ul.navTreeLevel2 li a {
font-size: 24px;
color: #004b93 !important;
}
ul.navTreeLevel2 {
margin-top: 5px;
}


ul.navTreeLevel3 li a {
font-size: 20px;
color: #000 !important;
}

#portal-sitemap > li {
border-top: 1px dotted #000;
}
}


// Webcode
#document-action-webcode {
color: #000;
display: inline-block;
font-size: 18px;
line-height: 1.7;
margin-bottom: 0;
max-height: 32px;
}

// Frontpage
body.portaltype-frontpage {
li#document-action-print {
display: none;
}
}

// image-caption vorübergehend ausgebländet
div.image-caption {
display: none;
}

// Seminar Region View
body.template-seminarregionview {
article#content {
font-size: 21px;
}
table.seminar_1 {
margin-top: 15px;
margin-bottom: 40px;
}
.quote-text {
margin: 25px 0;
}
h1.documentFirstHeading {
margin-bottom: -25px;
}
div.documentDescription {
margin-top: 20px;
}
a.sem_switch {
color: #004b93;
}
a.sem_switch:hover {
color: #0095db;
}
}

// Seminar Moderatoren

.container_moderatoren {
padding-top: 20px;
grid-template-columns: 50%;
grid-template-areas: "content sidebar";
}

.mod_auswahl {
padding-top: 20px;
}

@media screen and (min-width: 520px) {
.container_moderatoren {
display: grid;
padding-top: 20px;
grid-template-columns: 50%;
grid-template-areas: "content sidebar";
}
}

// Seminar View
body.template-seminarview {
article#content {
font-size: 21px;
}
ul {
list-style: outside none none;
}
#gda-logo {
float: right;
}
}

body.template-seminarmail {
table {
font-size: 21px;
}
}

body.template-seminaranmeldung {
#seminarformviewlet {
font-size: 21px;
}
.zeam-form fieldset {
background-color: #fff;
}

.zeam-form textarea, .zeam-form input[type="text"] {
background-color: #eee;
}
span.error {
padding-left: 30px;
font-weight: @plone-font-weight-regular;
&:before {
background-color: @plone-alert-error-bullet; content:"×";
font-size:@plone-font-size-base;
font-weight: @plone-font-weight-regular;
margin-left: -30px;
margin-right: 5px;
display: inline-block;
border-radius: 20px;
text-align:center;
color:rgba(255, 255, 255, 0.9);
width:16px;
height:16px;
line-height: 16px; //IE fix
text-shadow: 0 -1px rgba(0,0,0,.5);
top: -1px;
position: relative;
}
}
}

img[usemap], map area {
outline: none;
}

//body.template-event_listing {
//  div.location {
//    display: inline;
//    &:before {
//      content: "/ ";
//    }
//  }

//  div.cal_date {
//    box-shadow: none;
//  }
//}


a.btn-primary:hover {
background-color: #004b93;
color: white;
}


// MyAlbumView
.myalbum {
margin-top: 20px;
}

.myalbum-box {
height: 410px;
margin-top: 10px;

a {
display: table-cell;
height: 400px;
background-color: #f3f2f2;
width: 390px;
padding: 15px;
text-decoration: none;

.myalbum-title {
font-size: 24px;
color: #d40f14;
padding-top: 10px;
padding-left: 15px;
text-indent: -15px;
}

.myalbum-title:before {
font-family: FontAwesome;
content: '\f105';
padding-right: 5px;
}

.myalbum-description {
font-size: 16px;
color: #000000;
padding-top: 10px;
}

img {
width: 400px;
}


}

}


// Brakoform
.brakoheader {
margin-top: 90px;
text-indent: -25px;
margin-left: 25px;
}

// Medienshop Artikel
#archetypes-fieldname-activity_field,
#archetypes-fieldname-activity_place {
display: none;
}

.pattern-relateditems-buttons {
position: relative;
float: right;
}



// PLZ suche 
.portletPLZSuche > .portletHeader {
font-weight: normal;
}



// Print View
@media print {
a[href]:after {
content: none !important;
}

html{
transform: scale(0.8);
}

.portletContent, .navbar, .plone-nav, .documentActions, .mainnavigation-wrapper, .bgdirektLink,
.header-category, .teaser-slide-subject {
visibility: hidden;
height: 0px;
height: 0px;
margin: 0px;
padding: 0px;
}


.top-container {
padding-top: 40px;
margin-top: 0px;
}

.portletKontaktPortlet {
padding: 0px;
}

.row:after {
clear: none !important;
}

.row > div {
float: none;
}

.plone-toolbar-left-expanded {
padding-left: 0px;
}

.image-left {
float: none !important;
}

#main-container > div {
left: 0;
}

#parent-fieldname-text {
margin-bottom: 0px;
}

#main-container > row {
visibility: hidden;
}

#content {
margin-bottom: 0px !important;
}

#portal-logo > img {
margin-top: 0px;
}

#portal-header {
height: 0px;
}

#search-input, #portal-globalnav-collapse, #portal-globalnav-wrapper, #mainnavigation-wrapper,
#portal-footer-wrapper, #edit-zone {
visibility: hidden;
height: 0px;
height: 0px;
margin: 0px;
padding: 0px;
}
}



// Termin View
body.template-event_view {
#content ul > li {
font-style: normal;
}
div.event ul.details {
float: left;
box-shadow: none;
list-style-type: none;
padding: 0;
margin-left: 0;
color: #004b93;
font-style: normal;
margin-right: 55px;

li.event-calendar {
strong, img {
display: none;
}
a {
color: #004b93;
}

span:before {
content: "\f133";
font-family: FontAwesome;
font-style: normal;
font-weight: normal;
text-decoration: inherit;
font-size: 19px;
margin-right: 5px;
}
}
li.event-date span.timezone {
display: none;
}
abbr {
cursor: text;
border: none;
text-decoration: none;
}
li.event-url a:after {
font-family: 'FontAwesome';
content: "\f08e";
font-style: normal;
padding-left: 8px;
}

li.event-url a {
color: #004b93;
}
ul.event {

}
}
}

// Terminliste
body.template-event_listing {
abbr {
cursor: text;
border: none;
text-decoration: none;
}
a.event_ical {
img {
display: none;
}
span:before {
content: "\f133";
font-family: FontAwesome;
font-style: normal;
font-weight: normal;
text-decoration: inherit;
font-size: 19px;
margin-right: 5px;
}
}
div.cal_date {
margin: 0;
box-shadow: none;
border: none;
img {
height: 65px !important;
margin-bottom: 18px !important;
}
}
div.location {
display: inline;
}
div.documentByLine {
font-size: 19px;
color: #555;
font-style: italic;
}
a.event_ical {
font-size: 19px;
color: #555;
float: right;
}
a.event_url {
font-size: 21px;
}
a.event_url:after {
font-family: 'FontAwesome';
content: "\f08e";
font-style: normal;
padding-left: 8px;
}
ul.mode_selector {
display: none;
}
article.vevent {
border-bottom: 1px dotted #555;
padding-top: 25px;
padding-bottom: 20px;
h2, div, p, a {
margin-top: 0 !important;
margin-bottom: 0 !important;
}
p.description {
padding-top: 10px;
}
div.cal_info {
margin-left: 7.5em;
}
}
article.vevent:last-of-type {
border: none;
}
}

// Nachrichten View
body.template-newsitem_view {
div.leadImage {
float: left;
}
.newsImageContainer {
box-shadow: none;
border-radius: 0;
margin: 0;
margin-right: 25px;
margin-top: 5px;
width: 400px; 
figcaption {
white-space: normal;
width: 400px;
color: #004b93;
font-style: italic;
font-size: 17px;
}
}

}

span.externallink a {
color: #d40f14;
font-size: 21px;
font-weight: normal;
}
span.externallink-tuerkis a {
color: #007c84;
font-size: 21px;
font-weight: normal;
}
span.externallink a:hover {
text-decoration: underline;
}
span.externallink-tuerkis a:hover {
text-decoration: underline;
}
span.externallink::after {
color: #d40f14 !important;
}
span.externallink-tuerkis::after {
color: #007c84 !important;
}
// QuoteZeichen
span.quote-box {
font-style: italic;
font-size: 28px;
color: #004b93;
background: url(++theme++bgv/dist/img/quote.png) no-repeat;
padding: 36px 0 0 70px;
}

.portletTextplusPortlet {
font-size: 16px;
}

// Accordion
a.accordion-title {
background: #f3f2f2 url(/++theme++bgv/dist/img/bg_leftnav_level1_hover.png) no-repeat right center;
padding: 20px;
color: #014C93;
margin-top: 3px;
font-size: 21px;
display: block;
}
a.accordion-title.expanded {
background: #f3f2f2 url(/++theme++bgv/dist/img/bg_leftnav_level1_active.png) no-repeat right center;
}
div.accordion-text {
display: none;
border: 1px solid #f3f2f2;
padding: 20px;
font-size: 21px;
margin-top: -10px;
}
body#tinymce div.accordion-text {
display: block;
}

// TabView
li.tabview-title {
float: left;
display: block;
background-color: #e3e3e3;
color: #222;
padding: 15px;
margin-right: 4px;
}
li.tabview-title {
margin-bottom: 4px;
}
li.tabview-title:first-of-type {
xmargin-left: -40px;
}
li.tabview-title:hover {
background-color: #f3f2f2;
}
li.tabview-title.active {
background-color: #004b93;
color: white;
}
li.tabview-title:before {
content: none !important;
}
div.tabview-text.active {
display: block;
}
div.tabview-text {
clear: both;
background-color: #f3f2f2;
display: none;
}

.tab-pane ul {
list-style: none;
> li:before {
position: relative;
top: -2px;
left: -15px;
z-index: 1;
font-family: FontAwesome;
content: '\f111';
color: @bgv-blue-link;
font-size: 10px;
font-style: normal;
margin-left:-10px;
line-height: 0;
}
}

// Bildunterschrift
div.captioned {
  font-size: 17px;
  font-style: italic;
  color: #004b93;
  line-height: 25px;
}

// Kontaktformular
body.template-webcontactform {
  input#form-action-absenden:hover {
    background: #004b93;
    color: white;
  }
}

// Verschlagwortung Medienkatalog
div[id^="archetypes-fieldname-schlagworte"] {
    margin-bottom: 0px;
}

// Verschlagwortung Seiten
div[id^="formfield-form-widgets-IVerschlagwortung-schlagworte"] {
    margin-bottom: 0px;
}

// Verschlagwortung Layout

body.template-unterrubrik{
  [id^="column-content-portlets-portlet"] div section section p img{
    width: 100%;
  }
  h3, h3.quote.claim, #content ul > li, #relatedItemBox header{
    font-style: normal;
  }
  .dyn-content ul li a{
    font-size: 21px;
  }
  #verschlag_u_h{
    margin-bottom: 30px;
  }      
  .portletHeader{
    font: italic;
    font-size: 18px;
    color: #555; 
  }    
  .verschlag-content-portlets{
    margin-bottom: 30px;
  }
  .dyn-content{
    background-color: white;
    padding-top: 15px;
    text-align: center;
  }
  .dyn-content ul li a{
    background-color: white;
  }
  .dyn-title{
    font-style: italic;
    font-size: 28px;
    text-align: center;
  }
  .dyn-data-unterrub{
    margin-top: 15px;
    margin-bottom: 30px;
  }
  .dyn-content ul{
    padding: 0px;
    margin: 0px;  
  }
  .dyn-content ul > li{
    padding: 0px !important;
    margin: 0px;
  }
  .dyn-content ul li a:before{
    content: "\f105";
    font-family: 'FontAwesome';
    color: #000;
    padding-right: 8px;
  }
  .dyn-content ul{
    list-style-type: none;
  }
  .dyn-content ul li a:hover:before{
    color: #004b93;
  } 
  .dyn-content ul li a:hover{
    color: #004b93;
  }
  .dyn .dyn-content li a{
    color: #000;
  }
  .verschlag-card-title{
    font-size: 21px;
    color: #000;
  }
  .verschlag-card-title h2.portlet_title::after{
    font-size: 21px;
    padding-left: 8px;
    content: "\f105";
    font-family: 'FontAwesome';
    color: #000;
  }
  .verschlag-card-title:hover{
    color: #004b93;
  }
  .balken_black{
    font-size: 5px;
    background: #222;
    padding-right: 100%; 
  }
  .card-desc{
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 35px;
  }
  #medien:before{
    content: url("/++theme++bgv/dist/img/Icon-medien.png") '\A';
    white-space: pre;
    max-height: 83px;
    max-width: 83px;
  }
  #seminare:before{
    content: url("/++theme++bgv/dist/img/Icon-seminare.png") '\A';
    white-space: pre;
    max-height: 83px;
    max-width: 83px;
  }
  #regelwerk:before{
    content: url("/++theme++bgv/dist/img/Icon-regelwerk.png") '\A';
    white-space: pre;
    max-height: 83px;
    max-width: 83px;
  }
  .mehr-link-teaser-red{
    font-size: 24px;
  }
  .mehr-link-teaser-red:hover{
    font-size: 24px;
    color: #000;
  }
  .mehr-link-teaser-red:before{
    font-size: 24px;
  }
  .mehr-link-teaser-red:hover:before{
    font-size: 24px;
    color:#000;
  }
  .mehr-link-teaser-tuerkis{
    font-size: 24px;
  }
  .mehr-link-teaser-tuerkis:hover{
    font-size: 24px;
    color: #000;
  }
  .mehr-link-teaser-tuerkis:before{
    font-size: 24px;
  }
  .mehr-link-teaser-tuerkis:hover:before{
    font-size: 24px;
    color:#000;
  }
  #content p{
    font-size: 16px;
  }
  .mobile-row { 
    padding-left: 24px;
    padding-right: 24px;
  }
  .verschlag-content-portlets .portlet .portletContent p{
    line-height: 22px !important;
  }
  #verschlag_u_h p{
    font-size: 21px;
  }
  .verschlag-content-portlets .col-md-4 {
    padding-top: 15px;
    padding-bottom: 20px;
  }
  .contact-name{
    margin: 0px;
  }
  .contact-phone{
    margin: 0px;
  }
  .contact-mail{
    margin: 0px;
  }
  .card-img .card-img-link a img{
    width: 100%;
  }
  .contact-phone abbr i{
   padding-right: 10px; 
  }
  .contact-mail abbr i{
   padding-right: 10px;
  }
  #dyn_regelwerk .dyn-title:first-child {
    padding-top: 10px;
  } 
  #verschlag_u_h #parent-fieldname-text a {
    font-size: 21px;
  }
  @media (min-width: @plone-screen-xs-min){ 
    .mobile-row { 
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  @media (min-width: @plone-screen-sm-min){ 
    .mobile-row { 
      padding-left: 24px;
      padding-right: 25px;
    }
  }
  @media (min-width: @plone-screen-md-min) {
    #regelwerk {
      margin-right: 8px;
      margin-left: -27px
    }
    #medien {
      margin-left: -9px;
      margin-right: -9px;
    }
    #seminare{
      margin-left: 8px;
      margin-right: -25px;
    }
    #regelwerk ~ .dyn-content{
      margin-left: -26px;
      margin-right: 9px;
    }
    #seminare ~ .dyn-content{
      margin-right: -25px;
      margin-left: 9px;
    }
    #medien ~ .dyn-content{
      margin-right: -9px;
      margin-left: -9px;
    }
  }
  @media (max-width: @plone-screen-md-min) {
    .verschlag-content-portlets {
      margin-left: 0px !important;
      margin-right: 0px !important;
      display: block !important;
    }
    #content-portlets{
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

body.template-inhaltsseite, body.template-vertiefungsseite{
   #dyn_regelwerk{
     border-bottom: 1px dotted #000;
     padding-bottom: 25px;
   }
   #dyn_regelwerk .dyn-title:first-child {
     padding-top: 10px;
   }
   #dyn_regelwerk .dyn-content ul li,
   #dyn_medien .dyn-content ul li,
   #dyn_seminare .dyn-content ul li{
     text-indent: -15px;
   }
   #dyn_medien{
     border-bottom: 1px dotted #000;
     padding-bottom: 25px;
   }
   .dyn-content ul{
     padding-left: 93px;
   }
   .dyn-content ul li a{
     font-size: 21px;
   }
  .twocolumns{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .twocolumns:last-child{
    padding-bottom: 20px;
  }
  .twocolumns-1{
    width: max-content;
  }
  .dyn-content{
    background-color: white;
  }
  .dyn-content ul li a{
    background-color: white;
    list-style-type: none;
    color: #222;
    font-style: normal;
  }
  .dyn-content ul li a:before{
    content: "\f105";
    font-family: 'FontAwesome';
    color: #222;
    padding-right: 8px;
  }
  .dyn-content ul{
    background-color: white;
    list-style-type: none;
    line-height: 0px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .dyn-content ul li{
    padding-bottom: 0px !important;
  }
  .dyn-title{
    font-size: 28px;
    font-style: italic;
    padding-bottom: 10px;
    padding-top: 20px;
  }
  #content-portlets{
    background-color: white;
  }
  .blauer-pfeil-hoch{
    background: url("/++theme++bgv/dist/img/blauer-Pfeil-hoch.png");
    margin: 0px auto;
    display: block;
    background-repeat: no-repeat;
    height: 32px;
    width: 32px;
  }
  .blauer-pfeil-runter{
    background: url("/++theme++bgv/dist/img/blauer-Pfeil-runter.png");
    margin: 0px auto;
    display: block;
    background-repeat: no-repeat;
    height: 32px;
    width: 32px;
  }
  .img-dotted{
    margin: 0px auto;
    display: block;
    margin-top: 20px;
   }
   .portlet .image-inline{
     width: 400px;
     margin-right: 5px;
     margin-left: 0px;
   }
   .mehr-infos-a:before {
    content: "\f105";
    font-family: 'FontAwesome';
    color: #d40f14;
    padding-right: 8px;
    font-size: 21px;
   }
   #content-portlets{
     margin-top: 0px;
   }
   .icon-dyn{
     width: 65px;
     height: 65px;
     margin-right: 10px;
   }
   #content-portlets h2{
     margin-top: 0px;
     font-style: italic;
   }
   li.regelwerk_toggle_group:before{
     content: none !important;
   }
   li.medien_toggle_group:before{
     content: none !important;
   }
   li.seminare_toggle_group:before{
     content: none !important;
   }
   @media only screen and (max-width: 985px){
     .image-inline{
       padding-bottom: 20px;
     }
   }
   .medien-link-div{
     max-width: 80%;
     padding-top: 35px;
   }
   .medien-link{
     padding: 25px 30px 25px 30px;
     background: #555555;
     color: #fff;
     position: absolute;
     right: 0;
   }
   .medien-link:after{
     content: "\f105";
     font-family: 'FontAwesome';
     color: #fff;
     padding-left: 8px;
   }
   .medien-link:hover{
     background: #004b93;
   }
  .twocolumns .row .col-md-5{
    padding-right: 0px;
  }
  .twocolumns div{
    font-size: 21px !important;
  }
  .ms-grid{
    display: -ms-grid;
    -ms-grid-columns: 1fr 0px 0px 0px;
  }
//  #relatedItemBox .row {
//    padding-bottom: 40px;
//  }
  .viewlet-verschlagwortung .teaserviewlet-img .image-inline{
    padding-left: 0px;
    padding-right: 0px;
  }
  #viewlet-below-content-body{
    margin-top: 30px;
  }
  @media (max-width: @plone-screen-sm-min) {
    #main-container div .row{
      margin-left: 0px;
      margin-right: 0px;
    }
    #main-container .col-md-8 {
      padding-left: 0px;
      padding-right: 0px;
    }
    .twocolumns .row .col-md-4{
      padding-left: 0px !important;
    }
  }
  @media (min-width: @plone-screen-lg-min) {
   .twocolumns-1 .image-inline {
     max-width: 350px;
   }
  }
  @media (max-width: @plone-screen-lg-min) {
    .twocolumns-2 {
      width: 100%;
      padding-top: 10px;
    }
    .twocolumns-1{
      width: 100%;
    }
    .twocolumns-1 img{
      width: 100%;
    }
  }
  @media (max-width: @plone-screen-sm-min) {
    .twocolumns-2, .twocolumns-1 {
      padding-left: 0px;
    }
    .viewlet-verschlagwortung .objectlists {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  @media (max-width: @plone-screen-md-min) {
    .verschlag-content-portlets {
      margin-left: 0px !important;
      margin-right: 0px !important;
      display: block !important;
    }
    .twocolumns-1 .image-inline{
      padding-bottom: 0px;
    }
  }
}

.viewlet-verschlagwortung{
  .teaserviewlet-img{
    padding-bottom: 30px;
  }
  .teaserviewlet-artikel-link{
    font-size: 21px;
  }
  .teaserviewlet-artikel-link:before{
    content: "\f105";
    font-family: 'FontAwesome';
    color: #d40f14;
    padding-right: 8px;
    font-size: 21px;
  }
  .teaserviewlet-artikel-link:hover:before{
    color: #222222;
  }  
  .teaserviewlet-artikel-link:hover{
    color: #222222;
  }
  .kontaktviewlet-header{
    color: #d40f14;
  }
  .col-md-4 teaserviewlet-img > div{
    margin-top: -8px;
  }
  .teaserviewlet-header{
    font-size: 28px;
    font-style: italic;
    margin-top: 20px;
  }
  .teaserviewlet-artikel-p{
    font-size: 16px !important;
    line-height: 22px !important;
  }
  .teaserviewlet-artikel-p:before{
    font-size: 16px !important;
  }
  .teaserviewlet-img .image-inline{
    padding-bottom: 10px;
  }
  .teaserviewlet-img {
    padding-bottom: 0px;
  }
  .teaserviewlet-header{
    font-style: italic !important;
  }
  .viewlet-verschlagwortung{
    padding-top: 50px;
  }
  .teaserviewlet-header{
    padding-top: 40px;
  }
  .kontaktviewlet-header{
    padding-top: 40px;
  }
  li.regelwerk_toggle_group:before{
    content: none !important;
  }
  li.medien_toggle_group:before{
     content: none !important;
  }
  li.seminare_toggle_group:before{
     content: none !important;
  }
  @media (max-width: @plone-screen-md-min) {
    .teaserviewlet-img img{
      width: 100%;
    }
    #relatedItemBox .row {
      padding-bottom: 40px;
    }
  }
  @media (max-width: @plone-screen-sm-min){
    .teaserviewlet-img {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .teaserviewlet-header{
    margin-top: 0px;
  }
  @media (min-width: @plone-screen-md-min){
    #relatedItemBox .row .col-md-8{ 
      padding-left: 0px;
    }
    .image-inline{ 
      padding-bottom: 20px !important;
    }
  }
  @media (max-width: @plone-screen-sm-min){
    .teaserviewlet-img .image-inline{  
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  @media (max-width: @plone-screen-lg-min) and (min-width: @plone-screen-sm-min){
    .teaserviewlet-img{
      margin-right: 0px !important;
    }
  }
}


// lsb, lightbox, Lightbox

.lsb-image-title {
  font-family: 'Fira Sans', Helvetica, Arial, sans-serif !important;
}

.lightspeed-box img.lsb-image.lsb-image-loaded {
  margin:0px;
}
.lsb-close {
  width: 30px !important;
  height: 30px !important;
  top: 22px !important;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiM1NTU7b3BhY2l0eTowLjg7fS5jbHMtMntmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5FbGVtZW50IDE8L3RpdGxlPjxnIGlkPSJFYmVuZV8yIiBkYXRhLW5hbWU9IkViZW5lIDIiPjxnIGlkPSJFYmVuZV8xLTIiIGRhdGEtbmFtZT0iRWJlbmUgMSI+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iMzAiIGhlaWdodD0iMzAiLz48L2c+PGcgaWQ9IkViZW5lXzItMiIgZGF0YS1uYW1lPSJFYmVuZSAyIj48cGF0aCBjbGFzcz0iY2xzLTIiIGQ9Ik0xOC4zOCwxNWw1LjA3LTUuMDdhMS41OSwxLjU5LDAsMCwwLDAtMi4yNUwyMi4zMiw2LjU1YTEuNTksMS41OSwwLDAsMC0yLjI1LDBMMTUsMTEuNjIsOS45Myw2LjU1YTEuNTksMS41OSwwLDAsMC0yLjI1LDBMNi41NSw3LjY4YTEuNTksMS41OSwwLDAsMCwwLDIuMjVMMTEuNjIsMTUsNi41NSwyMC4wN2ExLjU5LDEuNTksMCwwLDAsMCwyLjI1bDEuMTMsMS4xM2ExLjU5LDEuNTksMCwwLDAsMi4yNSwwTDE1LDE4LjM4bDUuMDcsNS4wN2ExLjU5LDEuNTksMCwwLDAsMi4yNSwwbDEuMTMtMS4xM2ExLjU5LDEuNTksMCwwLDAsMC0yLjI1WiIvPjwvZz48L2c+PC9zdmc+); 
}

.lsb-close:hover {
  width: 30px !important;
  height: 30px !important;
  top: 22px !important;
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23004994;opacity:0.8;%7D.cls-2%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EElement 2%3C/title%3E%3Cg id='Ebene_2' data-name='Ebene 2'%3E%3Cg id='Ebene_1-2' data-name='Ebene 1'%3E%3Crect class='cls-1' width='30' height='30'/%3E%3C/g%3E%3Cg id='Ebene_2-2' data-name='Ebene 2'%3E%3Cpath class='cls-2' d='M18.38,15l5.07-5.07a1.59,1.59,0,0,0,0-2.25L22.32,6.55a1.59,1.59,0,0,0-2.25,0L15,11.62,9.93,6.55a1.59,1.59,0,0,0-2.25,0L6.55,7.68a1.59,1.59,0,0,0,0,2.25L11.62,15,6.55,20.07a1.59,1.59,0,0,0,0,2.25l1.13,1.13a1.59,1.59,0,0,0,2.25,0L15,18.38l5.07,5.07a1.59,1.59,0,0,0,2.25,0l1.13-1.13a1.59,1.59,0,0,0,0-2.25Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.lsb-download:hover {
  width: 38px !important;
  height: 38px !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 38 38'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23004994;opacity:0.8;%7D.cls-2%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EElement 3%3C/title%3E%3Cg id='Ebene_2' data-name='Ebene 2'%3E%3Cg id='Ebene_1-2' data-name='Ebene 1'%3E%3Crect class='cls-1' width='38' height='38'/%3E%3Cpath class='cls-2' d='M17.28,8h3.44a1,1,0,0,1,1,1v7.22h3.77a.86.86,0,0,1,.6,1.47l-6.53,6.54a.83.83,0,0,1-1.17,0l-6.55-6.54a.86.86,0,0,1,.61-1.47h3.77V9A1,1,0,0,1,17.28,8ZM30,24.16V29a1,1,0,0,1-1,1H9a1,1,0,0,1-1-1V24.16a1,1,0,0,1,1-1h6.31l2.1,2.1a2.21,2.21,0,0,0,3.12,0l2.11-2.1H29A1,1,0,0,1,30,24.16Zm-5.33,3.78a.86.86,0,1,0-.86.86A.86.86,0,0,0,24.67,27.94Zm2.75,0a.86.86,0,1,0-.86.86A.86.86,0,0,0,27.42,27.94Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.lsb-download {
  width: 38px !important;
  height: 38px !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 38 38'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23555;opacity:0.8;%7D.cls-2%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EElement 4%3C/title%3E%3Cg id='Ebene_2' data-name='Ebene 2'%3E%3Cg id='Ebene_1-2' data-name='Ebene 1'%3E%3Crect class='cls-1' width='38' height='38'/%3E%3Cpath class='cls-2' d='M17.28,8h3.44a1,1,0,0,1,1,1v7.22h3.77a.86.86,0,0,1,.6,1.47l-6.53,6.54a.83.83,0,0,1-1.17,0l-6.55-6.54a.86.86,0,0,1,.61-1.47h3.77V9A1,1,0,0,1,17.28,8ZM30,24.16V29a1,1,0,0,1-1,1H9a1,1,0,0,1-1-1V24.16a1,1,0,0,1,1-1h6.31l2.1,2.1a2.21,2.21,0,0,0,3.12,0l2.11-2.1H29A1,1,0,0,1,30,24.16Zm-5.33,3.78a.86.86,0,1,0-.86.86A.86.86,0,0,0,24.67,27.94Zm2.75,0a.86.86,0,1,0-.86.86A.86.86,0,0,0,27.42,27.94Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.lsb-control-panel {
  background: none !important;
}

.lsb-next {
  width: 38px !important;
  height: 38px !important;
  top: 50% !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 53.13 53.13'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23555;opacity:0.8;%7D.cls-2%7Bfill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EElement 5%3C/title%3E%3Cg id='Ebene_2' data-name='Ebene 2'%3E%3Cg id='Ebene_1-2' data-name='Ebene 1'%3E%3Ccircle class='cls-1' cx='26.56' cy='26.56' r='26.56'/%3E%3Cpolyline class='cls-2' points='19.5 11.33 33.62 26.19 19.5 41.79'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.lsb-next:hover {
  width: 38px !important;
  height: 38px !important;
  top: 50% !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 53.13 53.13'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%230095db;opacity:0.8;%7D.cls-2%7Bfill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EElement 6%3C/title%3E%3Cg id='Ebene_2' data-name='Ebene 2'%3E%3Cg id='Ebene_1-2' data-name='Ebene 1'%3E%3Ccircle class='cls-1' cx='26.56' cy='26.56' r='26.56'/%3E%3Cpolyline class='cls-2' points='19.5 11.33 33.62 26.19 19.5 41.79'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.lsb-prev {
  width: 38px !important;
  height: 38px !important;
  top: 50% !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 53.13 53.13'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23555;opacity:0.8;%7D.cls-2%7Bfill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EElement 4%3C/title%3E%3Cg id='Ebene_2' data-name='Ebene 2'%3E%3Cg id='Ebene_1-2' data-name='Ebene 1'%3E%3Ccircle class='cls-1' cx='26.56' cy='26.56' r='26.56'/%3E%3Cpolyline class='cls-2' points='33.62 11.33 19.5 26.19 33.62 41.79'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.lsb-prev:hover {
  width: 38px !important;
  height: 38px !important;
  top: 50% !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 53.13 53.13'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%230095db;opacity:0.8;%7D.cls-2%7Bfill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EElement 3%3C/title%3E%3Cg id='Ebene_2' data-name='Ebene 2'%3E%3Cg id='Ebene_1-2' data-name='Ebene 1'%3E%3Ccircle class='cls-1' cx='26.56' cy='26.56' r='26.56'/%3E%3Cpolyline class='cls-2' points='33.62 11.33 19.5 26.19 33.62 41.79'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.lsb-control-panel{
  opacity: 1 !important;
}

.lsb-panel-button {
  margin: 0px !important;
}

@media only screen and (max-width: 1980px) {
  .lsb-header {
    top: 87% !important;
  }
}

@media only screen and (max-width: 1280px) {
  .lsb-header {
    top: 85% !important;
  }
}

@media only screen and (max-width: 540px) {
  .lsb-header {
    top: 75% !important;
  }
}

@media only screen and (max-width: 380px) {
  .lsb-header {
    top: 62% !important;
  }
}

.plus-lsb:before {
  content: "\f055";
}

.plus-lsb {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 14px;
  font-size: 14px;
  font-size: inherit;
  position: relative;
  right: 24px;
  bottom: 4px;
  vertical-align: bottom;
  color: #d40f14;
  cursor: pointer;
}

h6{
  font-size: 34px;
  font-style: italic;
  margin-top: 0px;
}
// 2 Columns
#tinymce .twocolumns-1 .col-md-6 {
  border: 1px dotted #ccc !important;
}
#tinymce .twocolumns-2 .col-md-6 {
  border: 1px dotted #ccc !important;
}
#tinymce .twocolumns .col-md-6 {
  border: 1px dotted #ccc !important;
}

// Akkordeon (Neu)
#akkordeon-heading {
 color: #333;
 background-color: #f3f2f2;
 border-color: #f3f2f2;
 padding: 10px 15px;
 margin-top: 3px;
}

h4#akkordeon-title {
 margin: 0px;
}

#akkordeon-title > a {
  display: block;
  font-size: 21px;
  line-height: 30px;
  margin: 0 !important;
  padding: 8px 60px 8px 8px;
  color: #014C93;
  text-decoration: none;
  cursor: pointer;
}

#akkordeon-title > a.akkordeon-collapsed {
  background: #f3f2f2 url("/++theme++bgv/dist/img/bg_leftnav_level1_hover.png") no-repeat right center;
}

#akkordeon-title > a.akkordeon-expanded {
  background: #f3f2f2 url("/++theme++bgv/dist/img/bg_leftnav_level1_active.png") no-repeat right center;
}

div#akkordeon-body {
  display: none;
  padding: 15px;
  border: 1px solid #f3f2f2;
}

body#tinymce div#akkordeon-body {
  display: block !important;
}

.portlet:not(.portletNavigationTree) {
    margin-bottom: 25px;
}

div.event ul.event li.event-category{
display: none;
}

a.slide-read-more::after {
    padding-left: 5px;
}




.video-responsive{
  overflow:hidden;
  padding-bottom: 53.25%;
  //padding-bottom: 42.25%;
  position:relative;
  height:0;
  margin-bottom: 18px;
}

.video-responsive iframe{
left:0;
top:0;
height:100%;
width:100%;
xmax-width:640px;
position:absolute;
}


//.video-responsive{
//position: absolute;
//top: 0;
//left: 0;
//bottom: 0;
//right: 0;
//width: 100%;
//height: 100%;

//overflow:hidden;
//padding-bottom: 42.25%;
//position:relative;
//height:0;
//margin-bottom: 18px;
//}


//.video-responsive > iframe{
//width: 100%;
//height: auto;
//}

//overflow:hidden;
//padding-bottom: 70%;
//position:relative;
//height:0;
//margin-bottom: 18px;
//}

//.video-responsive iframe{
//left:0;
//top:0;
//height:100%;
//width:70%;
//xmax-width:640px;
//position:absolute;
//}


.cc_banner-wrapper {
    display: none;
}

.bullet_point_arrow {
    li::before {
        content: '\1F892' !important;
        font-size: 35px !important;
        top: 3px !important;
        color: #007c84 !important;
        left: -10px !important;
    }  
}

blockquote {
  margin-bottom: 20px;
}

.quote-text-tuerkis {
    padding-top: 0px;
    padding-bottom: 20px;
}


.quote-text-tuerkis + p  {
    margin-top: 15px;
}

.quote-link-tuerkis {
    padding-top: 0px;
    margin-bottom: 15px;
    margin-top: -15px;
}

h2.portlet_title {
  font-size: 21px;
  margin-top: 3px;
  margin-bottom: 4px;
}

h2.portlet_title_card {
  font-size: 21px;
  margin-top: 3px;
}

h2.portlet_title_card::after{
  font-size: 21px;
  padding-left: 6px;
  content: "\f105";
  font-family: 'FontAwesome';
  color: #004b93;
}

h2.portlet_title_purecard {
  font-size: 21px;
  margin-top: 2px;
  margin-bottom: 4px;
}

h2.portlet_title_purecard::after{
  font-size: 21px;
  padding-left: 6px;
  content: "\f105";
  font-family: 'FontAwesome';
  color: #d40f14;
}


div.container input[type=text],
div.container textarea, 
div.container select {
  border-bottom: 1px solid #555;
}

div.container input[type=text]:focus,
div.container textarea:focus, 
div.container select:focus {
  box-shadow: 0px 0px 0px 2px #004994;
  border-bottom: 1px solid transparent;
}

#plzGadget{
  width: calc(~"100% - 31px");
}


#plzGadget:focus {
  border-color: #004994;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px #004994;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px #004994;
}

#searchGadget:focus {
  border-color: #004994;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px #004994;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px #004994;
}

.searchPage input[type="text"]:focus {
  border-color: #004994 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px #004994 !important;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px #004994 !important;
}

.template-tabview {
  .table tr th,
  .table tr td {
    font-size: 21px;
  }
}

.template-accordionview {
  .table tr th,
  .table tr td {
    font-size:21px;
  }
}

@media (max-width: 769px){
#portal-logo{
display: block;
width: 157px;
}
}
@media (max-width: 768px) {
  #portal-header .search-fields {
    display: flex !important;
    width: 200%;
  }
  #portal-searchbox {
    display: block !important;
  }
}
@media (max-width: 768px){
#portal-header .searchbox-container #portal-searchbox .searchField {
    width: 100%;
}
}
#portal-header #portal-searchbox form > div {
    display: flex;
    flex-direction: row;
}
@media (max-width: 768px){
  #portal-header .searchbox-container {
    margin-top: 2em;
    margin-bottom: 0.4em;
    padding-right: 25px;
  }
  #mainnavigation-wrapper {
    height: 69px;
  }
}

.navbar-toggle .icon-bar:after {
  content: "Menü";
  position: relative;
  color: white;
}

@media (min-width: 750px) and (max-width: 1120px) {
  #portal-header #portal-searchbox {
    margin-right: 0px;
  }
}
