//*// HEADER //*//
#content-header {
    .bgdirektLinkMobile {
        @media (min-width: @plone-screen-sm-up) {
            display: none;
        }
    }
}
#portal-header {
    margin: 0 auto;
    #portal-languageselector {
        float: left;
        clear: both;
        display: inline-block;
        margin-right: 29px;
        padding-top: 5px;
        li {
            display: inline-block;
            margin-right: 10px;
        }
    }
    #portal-anontools {
        float: right;
        ul {
            padding-right: 0;
        }
    }
    //mobile search
    #portal-searchbox {
        padding-top: 20px;
        clear: left;
        width: 67%;
        position: absolute;
        z-index: 2;
        margin-left: 3px;
        margin-top: 60px;
        white-space: nowrap;
        //non on mobile
        [type="text"] {
            width: 100%;
            display: inline-block;
            background: @bgv-gray;
        }
        /*
        [type="submit"] {
            display: none; //submit hidden on mobile
        }
        */
        label {
            font-size: 86.667%;
        }
        //white space between elements inline-block
        form > div {
            font-size: 0;
            > * {
                font-size: @plone-font-size-base;
            }
        }
    }
    .bgdirektLink {
        @media (max-width: @plone-screen-sm-min) {
            display: none;
        }
    }
    //non mobile search
    @media (min-width:750px) and (max-width:1120px) {
	#portal-searchbox {float:right;margin-right:60px;}
	}
    @media (min-width:1025px) {
	#portal-searchbox {float:right;}
	}
    @media (min-width: @plone-grid-float-breakpoint) {
        #portal-languageselector {
            float: right;
            clear: none;
        }
        #portal-anontools {
            float: right;
        }
        #portal-searchbox {
            position: relative;
            margin-top: 5px; //visual balance
            margin-bottom: -5px; //visual balance
            //margin-left: 1em;
            padding-top: 0;
            //clear: none;
            //float: right;
            //max-width: 70%;
            width: 218px;
	    right:20px;
            white-space: nowrap;
            //margin-right: 10%;
            //yes for non mobile
            [type="text"] {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                width: calc(250px - 70px); //searchboxwidth - submit
                display: inline-block;
            }
            input[type="submit"] {
                font-family: FontAwesome;
            }
            .search-icon {
                cursor: pointer;
                display: none; //submit shown on mobile
                width: 34px;
                height: 34px;
                display: inline-block;
                margin-left: -4px;
                background: @bgv-blue-base;
                color: white;
                vertical-align: top;
            }
            label {
                font-size: 86.667%;
            }
            //white space between elements inline-block
            form > div {
                font-size: 0;
                > * {
                    font-size: @plone-font-size-base;
                }
            }
        }
        .bgdirektLink {
            & > a {
                float: right;
            }
        }
    }
    @media (max-width: @plone-screen-sm-min) {
        margin-bottom: 10px;
        .search-fields {
            display: none;
        }
        .searchbox-container {
            float: right;
            margin-top: 0.4em;
            margin-bottom: 0.4em;
            padding-right: 5px;
            #portal-searchbox {
                padding-top: 0;
                clear: left;
                width: 100%;
                position: relative;
                margin-top: 0;
                display: none;
                .searchField {
                    width: 80%;
                }
            }
        }
        .search-icon {
            float: right;
        }
    }
    .clearfix(); //clear floats
}
#portal-logo {
    position: relative;
    top: -62px;
}
