@media (max-width: @plone-screen-mobile-size) {
  .header-category {
    height: auto;
    max-height: 600px;
    .teaser-container {
      top: 0;
      transform: unset;
      width: 100%;
      padding-bottom: 1em;
    }
  }
}
@media (min-width: @plone-screen-sm-up) and (max-width: 1420px) {
  .carousel {
    overflow: hidden;
  }
  //.carousel-fade .carousel-caption{ top: 0px; }
  .header-category,
  .header-content {
    height: auto;
    .teaser-container {
      top: 0;
      transform: unset;
    }
  }
}
.carousel-fade {
  height: 30.996vw;
  max-height: 572px;
  @media (max-width: 1024px) {
    height: 30.6228vw;
  }
  /* added lucas */
  .carousel-control.left {
    width: 64px;
    background: transparent url("../dist/img/bg_header_left.png") no-repeat scroll left center;
    &:hover {
      background-image: url("../dist/img/bg_header_left_hover.png");
    }
    span {
      display: none;
    }
  }
  .carousel-control.right {
    width: 64px;
    background: transparent url("../dist/img/bg_header_right.png") no-repeat scroll right center;
    &:hover {
      cursor: pointer;
      background-image: url("../dist/img/bg_header_right_hover.png");
    }
    span {
      display: none;
    }
  }
  .carousel-caption {
    top: 16px;
    //  left: 5%;
    height: 26.6vw;
    .current-slide {
      height: 19.6vw;
      @media (max-width: @plone-screen-mobile-size) {
        height: calc(17.4vw + 8.6vw);
      }
    }
    h3:before {
      position: absolute;
      left: 0;
      z-index: 1;
      font-family: FontAwesome;
      content: '\f111';
      color: #5cacf9;
      font-size: 20px;
      margin-bottom: 0;
      font-style: normal;
    }
    .slide-read-more {
      color: #fff;
    }
    h3 {
      font-weight:@plone-font-weight-bold;
      font-style: normal;
      font-size: 1.7vw;
    }
    p {
      font-size: 1.2vw;
    }
  }
  .teaser-slides {
    height: 8.6vw;
    clear: both;
    @media (max-width: @plone-screen-mobile-size) {
      display: none;
    }
    h4 {
      font-weight: 700;
      font-size: 16px;
      margin-top: 1px;
    }
    @media (max-width: 1492px) {
      h4 {
        font-size: 1.2vw;
      }
    }
    h4:before {
      position: absolute;
      top: 24px;
      left: 8px;
      z-index: 1;
      font-family: FontAwesome;
      content: '\f111';
      color: #fff; // #5cacf9;
      font-size: 0.7em;
      margin-bottom: 0;
      font-style: normal;
    }
    > div {
      top: 48%;
    }
  }
  .carousel-inner {
    max-height: 572px;
    .item {
      transition-property: opacity;
      transition-duration: 1s;
    }
    .active.left,
    .active.right,
    .item {
      opacity: 0;
    }
    .active,
    .next.left,
    .prev.right {
      opacity: 1;
    }
    .active.left,
    .active.right,
    .next,
    .prev {
      left: 0;
      transform: translate3d(0, 0, 0);
    }
  }
  .carousel-control {
    z-index: 122;
  }
  // Disabled media query due to http://answers.microsoft.com/en-us/insider/forum/insider_internet-insider_spartan/microsoft-edge-this-page-is-having-a-problem/b3965bec-d484-4ad4-9e31-a81e61b44121?auth=1
  //@media (min-width:calc(@plone-screen-mobile-size + 1px)) {
    .hero_mobile {
      display: none;
    }
    .hero_desktop {
      display: inline;
    }
  //}
  @media (max-width: @plone-screen-mobile-size) {
    height: auto;
    max-height: 600px;
    .hero_mobile {
      display: inline;
    }
    .hero_desktop {
      display: none;
    }
    .carousel-caption {
      position: relative;
      top: 0;
      left: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      h3 {
        font-size: 28px;
      }
      p {
        font-size: 14px;
      }
    }
    .carousel-inner {
      max-height: 600px;
    }
  }
  @media (width: @plone-container-sm-full) {
    .carousel-caption {
      h3 {
        font-size: 20px;
        margin-top: 2px;
        margin-bottom: 5px;
      }
      p {
        font-size: 12px;
      }
      a.slide-read-more {
        font-size: 14px;
      }

    }
  }
}
.carousel-inner,
.carousel-inner .item,
body,
html {
  height: 100%;
}
/*
.item:nth-child(1) {
    background: #74C390;
}

.item:nth-child(2) {
    background: #51BCE8;
}

.item:nth-child(3) {
    background: #E46653;
}
*/
@media (max-width: 992px) {
  .carousel-fade .teaser-slides .col-xs-6 {
    padding: 0 10px 0 0;
  }
  .carousel-fade .teaser-slides .teaser-slide-subject {
    font-size: 1.0vw;
  }
  .carousel-fade .teaser-slides h4 {
    font-size: 1.0vw;
  }
  .carousel-fade .teaser-slides h4:before {
    content: '';
  }
}

@media (max-width: @plone-screen-mobile-size) {
    .carousel-fade .carousel-control.right {
    width: 38px;
    background: transparent url("../dist/img/bg_header_right-72.png") no-repeat scroll right center !important;
	}
    .carousel-fade .carousel-control.left {
    width: 38px;
    background: transparent url("../dist/img/bg_header_left-72.png") no-repeat scroll left center !important;
	}
}
