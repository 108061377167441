//@import "variables.plone.less";
.header-category {
    margin-top: -20px;
    height: 25.6579vw;
    max-height: 351px;
    // background: transparent url("images/header_category.jpg") no-repeat scroll center top / cover;
    background-color: @bgv-blue-base;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    .inside {
        width: 100%;
        max-width: 1368px;
        margin: 0 auto;
        position: relative;
        height: inherit;
        max-height: 351px;
    }
    .teaser-container {
        position: relative;
        transform: translateY(-50%);
        color: #FFF;
        padding-left: 1.75439vw;
        width: 50%;
        top: 50%;
        h1 {
            font-size: 2.63158vw;
            line-height: 1.11111;
            margin-bottom: 10px0;
        }
        p {
            font-size: 1.60819vw;
            line-height: 1.36364;
            margin-bottom: 0;
        }
    }
}
@media only screen and (min-width:1800px) {
	.header-category .teaser-container h1 {font-size: 1.7vw;}
	.header-category .teaser-container p {font-size: 1.2vw;}
}
@media only screen and (max-width: @plone-screen-mobile-size) {
    .header-category {
	margin-top:-1px;
        height: auto;
        max-height: 10000px;
        .teaser-container {
            padding-right: 3vw;
            padding-bottom: 6.25vw;
            padding-top: 6.25vw;
            width: 100%;
            height: auto;
            position: static;
            transform: none;
            top: 0;
            background-color: @bgv-blue-base;
            h1 {
                font-size: 4.5vw;
            }
            p {
                font-size: 2.625vw;
            }
        }
        & > .inside {
            height: auto;
            max-height: 10000px;
        }
    }
}

.header-content {
    margin-top:-20px;
    height: 13.8889vw;
    max-height: 190px;
    background-color: @bgv-blue-base;
    background-position: right top;
    background-repeat: no-repeat;
    background-size: cover;

    .inside {
        width: 100%;
        max-width: 1368px;
        margin: 0px auto;
        position: relative;
        height: inherit;
        max-height: 190px;
    }

    .teaser-container {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        color: #FFF;
        padding-left: 1.75439vw;
        width: 50%;
    }
}

@media only screen and (max-width: @plone-screen-mobile-size) {
    .header-content {
        height: auto;
        max-height: 10000px;
        .teaser-container {
            padding-right: 3vw;
            padding-bottom: 6.25vw;
            padding-top: 6.25vw;
            width: 100%;
            height: auto;
            position: static;
            transform: none;
            top: 0;
            background-color: @bgv-blue-base;
            h1 {
                font-size: 4.5vw;
            }
        }
        & > .inside {
            height: auto;
            max-height: 10000px;
        }
    }
}

@media only screen and (min-width: @plone-screen-mobile-size) {
    .hide-desktop {
        display: none !important;
    }
}
