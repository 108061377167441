.img-lightbox {
    img {
        cursor: pointer;
    }
    .enlargeImg {
        position: relative;
        right: 24px;
        bottom: 4px;
        vertical-align: bottom;
        color: @bgv-red-base;
        cursor: pointer;

        @media (max-width: @plone-screen-xs-min) {
            display: none;
        }
    }
}
