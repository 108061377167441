/* See http://bootsnipp.com/snippets/featured/mega-menu-slide-down-on-hover-with-carousel */


/* BEWARE: this is a simple CSS, not less */

.top-mega-menu .mega-dropdown {
    position: static !important;
}
.top-mega-menu .mega-dropdown-menu {
    padding: 20px 0px;
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
}
.top-mega-menu .mega-dropdown-menu > li > ul {
    padding: 0;
    margin: 0;
}
.top-mega-menu .mega-dropdown-menu > li > ul > li {
    list-style: none;
}
.top-mega-menu .mega-dropdown-menu > li > ul > li > a {
    font-size: 20px;
    display: block;
    color: #222;
    padding: 3px 5px;
}
.top-mega-menu .mega-dropdown-menu > li ul > li > a:hover, .top-mega-menu .mega-dropdown-menu > li ul > li > a:focus {
    text-decoration: none;
    color: #d40f14;
}
.top-mega-menu .mega-dropdown-menu .dropdown-header {
    font-size: 18px;
    color: #000;
    padding: 5px;
    line-height: inherit;
}
ul.mega-dropdown-menu-lv2 {
    list-style: none;
}
ul.mega-dropdown-menu-lv2 li {
    padding-left: 0;
    text-indent: -12px;
    margin-left: 12px;
}
ul.mega-dropdown-menu-lv2 > li > a {
    font-size: 18px !important;
    color: black;
}
ul.mega-dropdown-menu-lv1 > li > a::after {
    font-family: FontAwesome;
    padding-left: 6px;
    font-style: normal;
    content: '\f105';
    color: black;
}
ul.mega-dropdown-menu-lv2 > li > a::before {
    font-family: FontAwesome;
    padding-left: 0px;
    padding-right: 6px;
    font-style: normal;
    content: '\f105';
    color: black;
}
ul.mega-dropdown-menu-lv2 > li > a:hover::before, ul.mega-dropdown-menu-lv1 > li > a:hover::after {
    color: #d40f14;
}
.top-mega-menu .carousel-control {
    width: 30px;
    height: 30px;
    top: -35px;
}
.top-mega-menu .left.carousel-control {
    right: 30px;
    left: inherit;
}
.top-mega-menu .carousel-control .glyphicon-chevron-left, .top-mega-menu .carousel-control .glyphicon-chevron-right {
    font-size: 12px;
    background-color: #fff;
    line-height: 30px;
    text-shadow: none;
    color: #333;
    border: 1px solid #ddd;
}
